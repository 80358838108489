import React, { useEffect, useRef, useState } from "react";
import { useOutletContext, useParams ,useNavigate} from "react-router-dom";
import { RequestBackendAPI } from "../../../helpers/http";
import { toast,togglePreloader} from "../../../helpers/viewHelper";
import SelectField from "../../../widgets/Fields/SelectField";
import CustomInputField from "../../../widgets/Fields/CustomInputField";

function AddProduct() {
    const { setPageTitle } = useOutletContext();
    const navigate = useNavigate()
    const { id } = useParams();

    useEffect(() => {
        setPageTitle((id ? 'Edit' : 'Add') + ' Product');

        if(id){
            getProductInfo(id)
        } else {
            masterDropDown();
        }
    }, [id]);

    useEffect(() => {
        masterDropDown()
    },[]);

    const initialFormData = {
        id: '',
        code: '',
        name: '',
        detailed_type: '',
        material_status: '',
        veg_or_non_veg: '',
        is_billable: '',
        expiration_days: '',
        sale_ok: '',
        purchase_ok: '',
    };

    const pricesInitialFormData = [{
        location: '',
        stock_count: '',
        stock_update_note:'',
        uom: '',
        unit_price: '',
        // unit_price_tax: '',
        // unit_price_total: '',
    }];

    const priceNumberFields = [
        'stock_count',
        'unit_price',
        // 'unit_price_tax'
    ];

    const [formData, setFormData] = useState({ ...initialFormData });

    const [formDataPrices, setFormDataPrices] = useState([...pricesInitialFormData]);

    const stockUpdateModalRef = useRef(null);
    const [tempStockEditingPriceIndex, setStockEditingPriceIndex] = useState();
    const [tempStockCount, setTempStockCount] = useState(0);
    const [tempStockUpdateNote, setTempStockUpdateNote] = useState('');
    const [stockUpdateNoteError, setStockUpdateNoteError] = useState('');


    const [formErrors, setFormErrors] = useState({});
    const [editMode, setEditMode] = useState(false);

    const [productTypes, setProductTypes] = useState({});
    const [materialStatuses, setMaterialStatuses] = useState({});
    const [vegNonVegTypes, setVegNonVegTypes] = useState({});
    
    const [location, setLocation] = useState({});

    function handleInputChange(event) {
        const { name, value ,type, checked} = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    }

    // const handlePriceInputChange = (event, priceIndex) => {
    //     const { name, value } = event.target;

    //     let priceData = [...formDataPrices];

    //     priceData[priceIndex][name] = value;

    //     setFormDataPrices([...priceData]);
    // }

    const handlePriceInputChange = (event, priceIndex) => {
        let { name, value } = event.target;
        let priceData = [...formDataPrices];

        // Ensure the index is within bounds
        if (priceData[priceIndex]) {
            if (priceNumberFields.includes(name)) {
                if (value && !isNaN(Number(value))) {
                    // 
                } else {
                    value = '';
                }
            }

            priceData[priceIndex][name] = value;

            /* if (priceNumberFields.includes(name)) {
                let unitPrice = Number(priceData[priceIndex]['unit_price']),
                    taxPrice = Number(priceData[priceIndex]['unit_price_tax']);

                if (isNaN(unitPrice)) {
                    unitPrice = 0;
                }

                if (isNaN(taxPrice)) {
                    taxPrice = 0;
                }

                priceData[priceIndex]['unit_price_total'] = unitPrice + taxPrice;
            } */

            setFormDataPrices([...priceData]);
        } else {
            console.error(`Invalid price index: ${priceIndex}`);
        }
    };

    const handleNavigateProductList = () => {
        navigate("/admin/products");
    };

    const masterDropDown = () => {
        RequestBackendAPI({
            path: `productMaster.dropDowns`,
            method: 'POST',
            callback: (success, response) => {
                let newProductTypes = {};
                let newMaterialStatuses = {};
                let newVegNonVegTypes = {};
                let newLocation = {};

                if (success && response && response.data && response.data.dropDowns) {
                    const dropDowns = response.data.dropDowns;

                    if (dropDowns.productTypes && typeof(dropDowns.productTypes) == 'object' && !(dropDowns.productTypes instanceof Array)) {
                        newProductTypes = {...dropDowns.productTypes};
                    }

                    if (dropDowns.materialStatuses && typeof(dropDowns.materialStatuses) == 'object' && !(dropDowns.materialStatuses instanceof Array)) {
                        newMaterialStatuses = {...dropDowns.materialStatuses};
                    }

                    if (dropDowns.vegNonVeg && typeof(dropDowns.vegNonVeg) == 'object' && !(dropDowns.vegNonVeg instanceof Array)) {
                        newVegNonVegTypes = {...dropDowns.vegNonVeg};
                    } 
                    
                    if (dropDowns.locations && typeof(dropDowns.locations) == 'object' && !(dropDowns.locations instanceof Array)) {
                        newLocation = {...dropDowns.locations};
                    }
                }

                setProductTypes({...newProductTypes});
                setMaterialStatuses({...newMaterialStatuses});
                setVegNonVegTypes({...newVegNonVegTypes});
                setLocation({...newLocation});
            }
        });
    }

    const handleAddProduct = (e) => {
        e.preventDefault();
        submitProductForm(formData);
    };

    const submitProductForm = (submitData) => {
        let formData = {
            ...submitData,
            ...JSON.parse(JSON.stringify(submitData)),
            is_billable: submitData.is_billable ?? false,
            sale_ok: submitData.sale_ok ?? false,
            purchase_ok: submitData.purchase_ok ?? false,
            prices: [...formDataPrices],
        }
        togglePreloader('show');
        RequestBackendAPI({
            path: 'productMaster.save',
            method: 'POST',
            data: formData,
            callback: (success, response) => {
                if (success) {
                    toast('success', response.message ?? 'product add successfully.');
                    handleNavigateProductList()
                    togglePreloader('hide');
                } else {
                    setFormErrors(response && response.errors ? response.errors : {});
                    toast('error', response && response.message ? response.message : 'Unable to add product');
                    togglePreloader('hide');
                }
            }
        });
    };

    // HandleEditUser
    const getProductInfo = (productId) => {
        setFormData('');

        RequestBackendAPI({
            path: `productMaster.edit`,
            pathReplaces: {
                'id': productId,
            },
            method: 'POST',
            callback: (success, response) => {
                if (success  && response && response.data && response.data.product) {

                    let productData = {
                        ...response.data.product
                    };

                    // formData
                    setFormData({...productData})

                    // pricesInitialFormData
                    setFormDataPrices([...(response.data.product.prices ?? [])]);

                    // DropDownData
                    setEditMode(true);
                }else{
                    toast('error', response && response.message ? response.message : 'Unable to fetch product data');

                    navigate('/admin/products');
                }
            }
        });
    }
    // HandleEditUser

    // Add a new row
    const addRow = () => {
        setFormDataPrices([
            ...formDataPrices,
            {
                location: '',
                stock_count: '',
                uom: '',
                unit_price: '',
                // unit_price_tax: '',
                // unit_price_total: ''
            }
        ]);
    };

    // Delete a row by index
    const deleteRow = (index) => {
        setFormDataPrices(formDataPrices.filter((_, i) => i !== index));
    };

    
    const onEditStockCountClicked = (e, priceIndex) => {
        e.preventDefault();

        document.activeElement.blur();

        const priceEntry = formDataPrices[priceIndex];

        if (!stockUpdateModalRef.current) {
            stockUpdateModalRef.current = new window.bootstrap.Modal(document.getElementById('stockUpdateNoteModel'), {
                backdrop: 'static',
                keyboard: false,
            });
        }

        setStockEditingPriceIndex(priceIndex);
        setTempStockCount(priceEntry.stock_count ?? 0);
        setTempStockUpdateNote(priceEntry.stock_update_note ?? '');

        stockUpdateModalRef.current.show();
    };

    const onSaveStockUpdateModelClicked = (e) => {
        e.preventDefault();

        document.activeElement.blur();

        if (tempStockCount != formDataPrices[tempStockEditingPriceIndex].stock_count) {
            if (tempStockUpdateNote) {
                setStockUpdateNoteError('');

                let priceData = [...formDataPrices];

                priceData[tempStockEditingPriceIndex].stock_count = tempStockCount;
                priceData[tempStockEditingPriceIndex].stock_update_note = tempStockUpdateNote;

                setFormDataPrices([...priceData]);

                stockUpdateModalRef.current.hide();

                setStockEditingPriceIndex(null);
                setTempStockCount(0);
                setTempStockUpdateNote('');
            } else {
                setStockUpdateNoteError('Required');
            }
        } else {
            stockUpdateModalRef.current.hide();
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card p-2">
                        <div className="card-header">
                            <h5>Product</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleAddProduct}>
                                <div class="row">
                                    <div className="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="code">Code</label>
                                            <input
                                                type="text"
                                                name="code"
                                                className={`form-control ${formErrors.code ? 'is-invalid' : ''}`}
                                                value={formData.code}
                                                onChange={handleInputChange}
                                                readOnly={formData.id}
                                                autoFocus
                                            />
                                            {formErrors.code && <div className="invalid-feedback">{formErrors.code}</div>}
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="name">Product Name</label>
                                            <input
                                                type="text"
                                                name="name"
                                                className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                                                value={formData.name}
                                                onChange={handleInputChange}
                                            />
                                            {formErrors.name && <div className="invalid-feedback">{formErrors.name}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div className="col">
                                        <div className="form-group is-required-field">
                                            <label htmlFor="detailed_type">Product Type</label>
                                            <SelectField
                                                name="detailed_type"
                                                value={formData.detailed_type}
                                                options={productTypes}
                                                onChangeFn={handleInputChange}
                                                className={(formErrors.detailed_type ? 'is-invalid' : '')}
                                                disabled={formData.can_change_product_type} />
                                            {formErrors.detailed_type && <div className="invalid-feedback">{formErrors.detailed_type}</div>}
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="form-group is-required-field">
                                            <label htmlFor="material_status">Material Status</label>
                                            <SelectField
                                                name="material_status"
                                                value={formData.material_status}
                                                options={materialStatuses}
                                                onChangeFn={handleInputChange}
                                                className={(formErrors.material_status ? 'is-invalid' : '')} />
                                            {formErrors.material_status && <div className="invalid-feedback">{formErrors.material_status}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div className="col">
                                        <div className="form-group is-required-field">
                                            <label htmlFor="veg_or_non_veg">Veg or Non-Veg</label>
                                            <SelectField
                                                name="veg_or_non_veg"
                                                value={formData.veg_or_non_veg}
                                                options={vegNonVegTypes}
                                                onChangeFn={handleInputChange}
                                                className={(formErrors.veg_or_non_veg ? 'is-invalid' : '')} />
                                            {formErrors.veg_or_non_veg && <div className="invalid-feedback">{formErrors.veg_or_non_veg}</div>}
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="expiration_days">Expiration Days</label>
                                            <input
                                                type="text"
                                                name="expiration_days"
                                                className={`form-control ${formErrors.expiration_days ? 'is-invalid' : ''}`}
                                                value={formData.expiration_days}
                                                onChange={handleInputChange}
                                            />
                                            {formErrors.expiration_days && <div className="invalid-feedback">{formErrors.expiration_days}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div className="form-group mb-3">
                                            <div className="icheck-primary d-inline">
                                                <input
                                                    style={{width:'25px',height:'25px'}}
                                                    type="checkbox"
                                                    id="is_billable"
                                                    name="is_billable"
                                                    checked={formData.is_billable}
                                                    onChange={handleInputChange}
                                                />
                                                <label htmlFor="is_billable">Is Billable</label>
                                            </div>
                                            {formErrors.is_billable && <div className="invalid-feedback">{formErrors.is_billable}</div>}
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="form-group mb-3">
                                            <div className="icheck-primary d-inline">
                                                <input
                                                    style={{width:'25px',height:'25px'}}
                                                    type="checkbox"
                                                    id="sale_ok"
                                                    name="sale_ok"
                                                    checked={formData.sale_ok}
                                                    onChange={handleInputChange}
                                                />
                                                <label htmlFor="sale_ok">Can be Sold</label>
                                            </div>
                                            {formErrors.sale_ok && <div className="invalid-feedback">{formErrors.sale_ok}</div>}
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="form-group mb-3">
                                            <div className="icheck-primary d-inline">
                                                <input
                                                    style={{width:'25px',height:'25px'}}
                                                    type="checkbox"
                                                    id="purchase_ok"
                                                    name="purchase_ok"
                                                    checked={formData.purchase_ok}
                                                    onChange={handleInputChange}
                                                />
                                                <label htmlFor="purchase_ok">Can be Purchased</label>
                                            </div>
                                            {formErrors.purchase_ok && <div className="invalid-feedback">{formErrors.purchase_ok}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-12">
                                        <h2 className="d-flex align-items-center">
                                            <span>Prices</span>
                                            <a className="text-primary ml-3" href="javascript:void(0);" style={{fontSize: '0.7em'}} onClick={addRow}>
                                                <i className="fas fa-plus-circle"></i>
                                            </a>
                                        </h2>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="is-required-field">Location</th>
                                                    <th className="is-required-field">Stock Count</th>
                                                    <th className="is-required-field">UoM</th>
                                                    <th className="is-required-field">Unit Price</th>
                                                    {/* <th>Tax</th>
                                                    <th>Total</th> */}
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    formDataPrices.length > 0 && formDataPrices.map((price, priceIndex) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <SelectField
                                                                        name="location"
                                                                        value={price.location}
                                                                        options={location}
                                                                        onChangeFn={e => handlePriceInputChange(e, priceIndex)}
                                                                        className={(formErrors.prices && formErrors.prices[`${priceIndex}`] && formErrors.prices[`${priceIndex}`].location ? 'is-invalid' : '')}
                                                                        disabled={formData.id && price.id} />
                                                                    {
                                                                        formErrors.prices && formErrors.prices[`${priceIndex}`] && formErrors.prices[`${priceIndex}`].location &&
                                                                        <div className="invalid-feedback">{formErrors.prices[`${priceIndex}`].location}</div>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <div className="form-group">
                                                                        <div className="input-group">
                                                                            <CustomInputField
                                                                                name="stock_count"
                                                                                value={price.stock_count}
                                                                                fieldType="number"
                                                                                onChangeFn={e => handlePriceInputChange(e, priceIndex)}
                                                                                className={
                                                                                    (
                                                                                        formErrors.prices && formErrors.prices[`${priceIndex}`] &&
                                                                                        (
                                                                                            formErrors.prices[`${priceIndex}`].stock_count
                                                                                            ||
                                                                                            formErrors.prices[`${priceIndex}`].stock_update_note
                                                                                        ) ? 'is-invalid' : ''
                                                                                    )
                                                                                }
                                                                                disabled={price.price_id} />
                                                                            {
                                                                                price.price_id &&
                                                                                <div className="input-group-append">
                                                                                    <button type="button" className="btn btn-info" onClick={e => onEditStockCountClicked(e, priceIndex)}>
                                                                                        <i className="fas fa-pen"></i>
                                                                                    </button>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            formErrors.prices && formErrors.prices[`${priceIndex}`] && formErrors.prices[`${priceIndex}`].stock_count &&
                                                                            <div className="invalid-feedback">{formErrors.prices[`${priceIndex}`].stock_count}</div>
                                                                        }
                                                                        {
                                                                            formErrors.prices && formErrors.prices[`${priceIndex}`] && formErrors.prices[`${priceIndex}`].stock_update_note &&
                                                                            <div className="invalid-feedback">{formErrors.prices[`${priceIndex}`].stock_update_note}</div>
                                                                        }
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        name="uom"
                                                                        className={"form-control " + (formErrors.prices && formErrors.prices[`${priceIndex}`] && formErrors.prices[`${priceIndex}`].uom ? 'is-invalid' : '')}
                                                                        value={price.uom}
                                                                        onChange={e => handlePriceInputChange(e, priceIndex)}
                                                                    />
                                                                    {
                                                                        formErrors.prices && formErrors.prices[`${priceIndex}`] && formErrors.prices[`${priceIndex}`].uom &&
                                                                        <div className="invalid-feedback">{formErrors.prices[`${priceIndex}`].uom}</div>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <CustomInputField
                                                                        name="unit_price"
                                                                        value={price.unit_price}
                                                                        fieldType="amount"
                                                                        onChangeFn={e => handlePriceInputChange(e, priceIndex)}
                                                                        className={
                                                                            (
                                                                                formErrors.prices && formErrors.prices[`${priceIndex}`] && formErrors.prices[`${priceIndex}`].unit_price ? 'is-invalid' : ''
                                                                            )
                                                                        } />
                                                                    {
                                                                        formErrors.prices && formErrors.prices[`${priceIndex}`] && formErrors.prices[`${priceIndex}`].unit_price &&
                                                                        <div className="invalid-feedback">{formErrors.prices[`${priceIndex}`].unit_price}</div>
                                                                    }
                                                                </td>
                                                                {/* <td>
                                                                    <input
                                                                        type="text"
                                                                        name="unit_price_tax"
                                                                        className="form-control"
                                                                        value={price.unit_price_tax}
                                                                        onChange={e => handlePriceInputChange(e, priceIndex)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        name="unit_price_total"
                                                                        className="form-control"
                                                                        value={price.unit_price_total}
                                                                        onChange={e => handlePriceInputChange(e, priceIndex)}
                                                                        readOnly
                                                                    />
                                                                </td> */}
                                                                <td>
                                                                    <div>
                                                                        <a className="text-primary mt-5" href="javascript:void(0);" onClick={addRow}>
                                                                            <i className="fas fa-plus-circle" style={{fontSize:'1.1em'}}></i>
                                                                        </a>
                                                                        &nbsp;|&nbsp;
                                                                        <a className="text-danger" href="javascript:void(0);"  onClick={() => deleteRow(priceIndex)}>
                                                                            <i className="fas fa-trash " style={{fontSize:'1.1em'}}></i>
                                                                        </a>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }

                                                {
                                                    formDataPrices.length == 0 &&
                                                    <>
                                                        <tr>
                                                            <td colSpan="6" className="text-center">
                                                                No prices added for the Product. Click <a href="javascript:void(0);" onClick={addRow}>here</a> to add one
                                                            </td>
                                                        </tr>
                                                    </>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-end">
                                        <button type="button" className="btn btn-default" onClick={handleNavigateProductList}>
                                            <i className="fas fa-angles-left"></i>&nbsp;Go Back
                                        </button>
                                    </div>

                                    <div className="col">
                                        <button type="submit" className="btn btn-primary">
                                            {formData.id ? 'Update' : 'Save'}
                                        </button>
                                    </div>
                                </div>

                                <div class="modal fade" id="stockUpdateNoteModel" tabindex="-1" role="dialog" aria-labelledby="stockUpdateNoteModelTitle" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-scrollable" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="stockUpdateNoteModelTitle">Stock Update</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label for="temp_stock_count">Stock count</label>
                                                            <input
                                                                type="number"
                                                                name="temp_stock_count"
                                                                className="form-control"
                                                                value={tempStockCount}
                                                                onChange={e => setTempStockCount(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label for="temp_stock_update_note">Why do you want to update the Stock?</label>
                                                            <textarea
                                                                name="temp_stock_update_note"
                                                                className={"form-control " + (stockUpdateNoteError ? 'is-invalid' : '')}
                                                                value={tempStockUpdateNote}
                                                                onChange={e => setTempStockUpdateNote(e.target.value)}
                                                            />
                                                            {stockUpdateNoteError && <div className="invalid-feedback">{stockUpdateNoteError}</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                                <button type="button" class="btn btn-primary" onClick={onSaveStockUpdateModelClicked}>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddProduct;
