import { useEffect, useRef, useState } from "react";
import $ from 'jquery';
import DivWithPreLoader from "../../widgets/DivWithPreLoader";
import { RequestBackendAPI } from "../../helpers/http";
import { setWindowTitle, toast } from "../../helpers/viewHelper";
import { useNavigate, useOutletContext } from "react-router-dom";
import Tooltip from "../../widgets/Tooltip";

export default function BillingScreenLayout1() {
    const originalWindowTitle = 'Bill an Order';

    const initialOrderData = {
        'orderId': null,
        'orderNo': null,
        'products': [],
        'customer_mobile_no': '',
        'payment_mode': null,
        'calculations': [],
        'currencySymbol': '&#8377;',
        'currencySymbolPosition': 'before',
    };

    const initialProductData = {
        'line_id': null,
        'product_id': null,
        'code': null,
        'name': null,
        'qty': null,
        'unit_price': null,
    };

    const initialFocusedTdData = {
        'productIndex': -1,
        'td': 'code',
    };

    const [ isLoading, toggleProgressBar ] = useState(false);

    const prevOrderData = useRef(null);
    const [ orderData, setOrderData ] = useState({...initialOrderData});

    const [ orderFormErrors, setOrderFormErrors ] = useState({...initialOrderData});

    const [ ddPaymentModes, setDdPaymentModes ] = useState({});

    const prevFocusedProductTd = useRef(null);
    const [ focusedProductTd, setFocusedProductTd ] = useState({...initialFocusedTdData});

    useEffect(() => {
        moveCursorToFocusedTd();
    }, [focusedProductTd, orderData]);

    useEffect(() => {
        if (JSON.stringify(prevOrderData.current ?? {}) != JSON.stringify(orderData)) {
            prevOrderData.current = {...orderData};

            if (orderData.products.length === 0) {
                addNewProduct();
            } else {
                calculatePrices();
            }
        }
    }, [orderData]);

    const moveCursorToFocusedTd = () => {
        if (!$(document.activeElement).is('input')) {
            const focusedTd = $(`#new_sale_order_table > tbody > tr[data-product-index="${focusedProductTd.productIndex}"] td.is-focused`);

            if (focusedTd && focusedTd.length > 0 && !focusedTd.is(':focus')) {
                focusedTd.trigger('focus');
            }
        }
    };

    const onOrderFieldChanged = (e, fieldName) => {
        let value = e.target.value;

        setOrderData(prevOrderData => {
            return {
                ...prevOrderData,
                [fieldName]: value,
            }
        });
    };

    const addNewProduct = (productIndex = -1) => {
        if (productIndex < 0) {
            productIndex = orderData.products.length;
        }

        setOrderData(prevOrderData => {
            return {
                ...prevOrderData,
                'products': [
                    ...prevOrderData.products,
                    {...initialProductData},
                ]
            };
        });

        setFocusedProductTd({
            'productIndex': productIndex,
            'td': 'code',
        });
    };

    const onProductTdFocused = (e, productIndex, tdName) => {
        if (focusedProductTd.productIndex != productIndex || focusedProductTd.td != tdName) {
            setFocusedProductTd({
                'productIndex': productIndex,
                'td': tdName,
            });
        }
    };

    const onKeyPressed = (e) => {
        if ($(document.activeElement).is('input') || $(document.activeElement).is('button')) {
            // 
        } else {
            const pressedKey = e.key.toLowerCase();
            let button = $(`.is-num-pad .btn-numpad[data-key*="${$.escapeSelector(pressedKey)}"]`);

            if (button.length > 0) {
                if (button.attr('data-key').includes(',') > -1) {
                    if (button.attr('data-key').split(',').indexOf(pressedKey) > -1) {
                        button.trigger('click');
                    }
                } else {
                    button.trigger('click');
                }
            }
        }
    };

    const onNumpadBtnClicked = (e) => {
        e.preventDefault();

        if ($(document.activeElement).is(e.target)) {
            document.activeElement.blur();
        }

        const pressedKey = ($(e.target).attr('data-key') ?? '').trim();
        const editingTdElement = $(`#new_sale_order_table > tbody > tr[data-product-index="${focusedProductTd.productIndex}"] td.is-focused`);
        const editingTdName = editingTdElement.attr('data-field-name');

        if (!(pressedKey && editingTdName)) {
            return;
        }

        if (isLoading) {
            return;
        }

        const isNumberKey = /^[0-9]+$/.test(pressedKey);

        let originalContent = String(orderData.products[focusedProductTd.productIndex][editingTdName] ?? ''),
            updatedContent = String(originalContent);

        if (pressedKey === 'enter') {
            if (focusedProductTd.td === 'code') {
                setFocusedProductTd({
                    'productIndex': focusedProductTd.productIndex,
                    'td': 'qty',
                });
            } else if (focusedProductTd.td === 'qty') {
                if ((orderData.products.length - 1) === focusedProductTd.productIndex) {
                    addNewProduct();
                } else {
                    setFocusedProductTd({
                        'productIndex': focusedProductTd.productIndex + 1,
                        'td': 'code',
                    });
                }
            }
        } else if (pressedKey.includes('backspace') || pressedKey.includes('delete')) {
            updatedContent = originalContent.slice(0, -1);
        } else if (pressedKey === '+' || pressedKey === '-') {
            if (!isNaN(Number(originalContent))) {
                if (pressedKey === '+') {
                    updatedContent = String(Number(originalContent) + 1);
                } else if (pressedKey === '-') {
                    updatedContent = String(Number(originalContent) - 1);
                }
            }
        } else if (isNumberKey) {
            if (editingTdElement.attr('data-max-digits')) {
                if (Number(editingTdElement.attr('data-max-digits')) === updatedContent.length) {
                    $('.is-num-pad .btn-numpad[data-key="enter"]').trigger('click');
                } else {
                    updatedContent += pressedKey;
                }
            } else {
                updatedContent += pressedKey;
            }
        }

        if (updatedContent != originalContent) {
            setOrderData(prevOrderData => {
                let updatedProductsData = [...prevOrderData.products];

                updatedProductsData[focusedProductTd.productIndex] = {
                    ...updatedProductsData[focusedProductTd.productIndex],
                    [editingTdName]: updatedContent,
                };

                return {
                    ...prevOrderData,
                    'products': [
                        ...updatedProductsData,
                    ]
                };
            });
        }
    };

    const validateAndGetFormData = (showValidationErr = true, delProductIndex = -1) => {
        let validatedProducts = [],
            newFormErrors = {},
            isValid = true;

        for (let index = 0; index < orderData.products.length; index++) {
            let product = orderData.products[index];

            if (delProductIndex === index) {
                continue;
            }

            let isProductInvalid = true, isQtyInvalid = true;

            if (product.code && !isNaN(Number(product.code))) {
                isProductInvalid = false;
            }

            if (product.qty && !isNaN(Number(product.qty))) {
                isQtyInvalid = false;
            }

            if (showValidationErr) {
                if (isProductInvalid || isQtyInvalid) {
                    if (typeof(newFormErrors.products) == 'undefined') {
                        newFormErrors.products = new Array(orderData.products.length).fill({
                            'code': '',
                            'qty': '',
                        });
                    }
                }

                if (isProductInvalid) {
                    newFormErrors.products[index].code = 'Invalid Product';
                }

                if (isQtyInvalid) {
                    newFormErrors.products[index].qty = 'Invalid Quantity';
                }
            }

            if (isProductInvalid || isQtyInvalid) {
                if ((orderData.products.length - 1) !== index) {
                    isValid = false;
                }
            } else {
                validatedProducts.push(product);
            }
        }

        if (showValidationErr) {
            if (!orderData.payment_mode) {
                newFormErrors.payment_mode = 'Invalid Payment mode.';
                isValid = false;
            }
        }

        setOrderFormErrors({...newFormErrors});

        return {
            'isValid': isValid,
            'products': validatedProducts,
        }
    }

    const calculatePrices = (reqData) => {
        try {
            // document.activeElement.blur();

            toggleProgressBar(true);

            if (!reqData) {
                if (focusedProductTd.productIndex > -1) {
                    let product = orderData.products[focusedProductTd.productIndex];

                    if (product && product.code && product.qty) {
                        reqData = {
                            'productCode': product.code,
                            'qty': product.qty,
                        };
                    } else {
                        toggleProgressBar(false);
                        return;
                    }
                } else {
                    toggleProgressBar(false);
                    return;
                }
            }

            let validation = validateAndGetFormData(false, reqData['delProductIndex'] ?? -1);

            RequestBackendAPI({
                path: 'billing.calcPrice.by-ProductCode',
                method: 'POST',
                data: {
                    ...reqData,
                    'products': validation.products,
                },
                callback: (success, response) => {
                    let productInfo = {},
                        calculations = [],
                        deleteProduct = false;

                    if (success) {
                        if (response.data) {
                            if (response.data.product) {
                                productInfo = {
                                    ...response.data.product,
                                };
                            }

                            if (response.data.calculations) {
                                calculations = [
                                    ...response.data.calculations,
                                ];
                            }
                        }

                        deleteProduct = reqData.action === 'delete';
                    } else {
                        let message = 'Unable to get the product details.';

                        if (response.data && response.data.message) {
                            message = response.data.message.replace('\n', '<br>');
                        } else if (response.message) {
                            message = response.message;
                        }

                        toast('error', message);
                    }

                    if (deleteProduct) {
                        setOrderData(prevOrderData => {
                            let updatedProductsData = [...prevOrderData.products];

                            updatedProductsData.splice(reqData['delProductIndex'], 1);

                            return {
                                ...prevOrderData,
                                'products': [
                                    ...updatedProductsData,
                                ],
                                'calculations': calculations,
                            };
                        });
                    } else {
                        setOrderData(prevOrderData => {
                            let updatedProductsData = [...prevOrderData.products];

                            updatedProductsData[focusedProductTd.productIndex] = {
                                ...updatedProductsData[focusedProductTd.productIndex],
                                ...productInfo,
                            };

                            return {
                                ...prevOrderData,
                                'products': [
                                    ...updatedProductsData,
                                ],
                                'calculations': calculations,
                            };
                        });

                        setFocusedProductTd({
                            'productIndex': focusedProductTd.productIndex,
                            'td': 'qty',
                        });
                    }

                    toggleProgressBar(false);
                },
            });
        } catch(err) {
            console.log('[calculatePrices] error occurred:', err);

            toggleProgressBar(false);
            moveCursorToFocusedTd();
        }
    };

    const onBtnDeleteProductClicked = (e, productIndex) => {
        e.preventDefault();

        document.activeElement.blur();

        calculatePrices({
            'action': 'delete',
            'delProductIndex': productIndex,
        });
    };

    const onBtnPrintClicked = (e) => {
        e.preventDefault();

        document.activeElement.blur();

        let defaultAjaxSuccessMessage = 'The order has been created successfully.',
            defaultAjaxFailedMessage = 'Unable to crate an Order',
            defaultErrMessage = 'Error occurred when trying to Create an Order.';

        try {
            // document.activeElement.blur();

            toggleProgressBar(true);

            let validation = validateAndGetFormData(),
                ajaxURL = 'billing.createOrder',
                ajaxPathParams = {};

            if (!validation['isValid']) {
                toggleProgressBar(false);
                toast('error', 'There are some invalid fields found. Please correct them all to proceed.');

                return;
            }

            if (orderData.id) {
                ajaxURL = 'billing.editOrder';
                ajaxPathParams = {
                    'id': orderData.id,
                };
                defaultAjaxSuccessMessage = 'The order has been updated successfully.';
                defaultAjaxFailedMessage = 'Unable to update the Order';
                defaultErrMessage = 'Error occurred when trying to update the Order.';
            }

            RequestBackendAPI({
                path: ajaxURL,
                pathReplaces: ajaxPathParams,
                method: 'POST',
                data: {
                    ...orderData,
                    'products': validation.products,
                },
                callback: (success, response) => {
                    let message, responseData;

                    if (response) {
                        if (response.message) {
                            message = response.message;
                        }

                        if (response.data) {
                            responseData = response.data;

                            if (response.data.message) {
                                message = response.data.message.replace('\n', '<br>');
                            }
                        }
                    }

                    if (success) {
                        if (orderData.id) {
                            if (responseData && responseData.orderData) {
                                setOrderData(prevOrderData => {
                                    return {
                                        ...prevOrderData,
                                        ...responseData.orderData,
                                    };
                                });

                                addNewProduct(0);
                            }
                        } else {
                            setOrderData({
                                ...initialOrderData,
                            });

                            addNewProduct(0);
                        }

                        if (responseData && responseData.printContentHTML) {
                            $('.portal-sale-order-receipt').remove();
                            $('body').append(responseData.printContentHTML);
                        }

                        toast('success', message ?? defaultAjaxSuccessMessage);

                        const totalImagesInPrint = $('.portal-sale-order-receipt img').length;
                        let imagesLoaded = 0;

                        if (totalImagesInPrint == 0) {
                            window.print();
                        } else {
                            $('.portal-sale-order-receipt img').on('load', () => {
                                imagesLoaded++;

                                if (totalImagesInPrint == imagesLoaded) {
                                    window.print();
                                }
                            }).on('error', () => {
                                imagesLoaded++;

                                if (totalImagesInPrint == imagesLoaded) {
                                    window.print();
                                }
                            });
                        }
                    } else {
                        if (responseData && responseData.validationErrors) {
                            setOrderFormErrors(prevErrors => {
                                return {
                                    ...prevErrors,
                                    ...responseData.validationErrors,
                                };
                            });
                        }

                        toast('error', message ?? defaultAjaxFailedMessage);
                    }

                    toggleProgressBar(false);
                },
            });
        } catch(err) {
            console.log('[onBtnPrintClicked] error occurred:', err);

            toggleProgressBar(false);
            moveCursorToFocusedTd();

            toast('error', defaultErrMessage);
        }
    };

    const onBeforePrint = () => {
        toggleProgressBar(true);

        let orderNo = $('.portal-sale-order-receipt .portal-sale-order-receipt-order-name').text();

        if (orderNo && orderNo.trim()) {
            setWindowTitle('Order #' + orderNo.trim());
        }
    };

    const onAfterPrint = () => {
        if (originalWindowTitle != document.title.trim()) {
            setWindowTitle(originalWindowTitle);
        }

        toggleProgressBar(false);

        $('.portal-sale-order-receipt').remove();
    };

    const getDropDowns = () => {
        try {
            RequestBackendAPI({
                path: 'billing.dropDowns',
                method: 'POST',
                callback: (success, response) => {
                    let errMessage = 'Unable to get Payment modes from Server.',
                        newPaymentModes = {};

                    if (success) {
                        if (response && response.data) {
                            if (response.data.paymentModes && !Array.isArray(response.data.paymentModes)) {
                                newPaymentModes = response.data.paymentModes;
                            }
                        }
                    } else {
                        if (response && response.message) {
                            errMessage = response.message;
                        }

                        toast('error', errMessage);
                    }

                    setDdPaymentModes({...newPaymentModes});
                },
            });
        } catch(err) {
            console.log('[getDropDowns] Error occurred:', err);
        }
    };

    const isFieldInvalid = (fieldName) => {
        if (orderFormErrors && typeof(orderFormErrors[fieldName]) != 'undefined') {
            return orderFormErrors[fieldName];
        }

        return false;
    };

    useEffect(()=> {
        setWindowTitle(originalWindowTitle);

        addNewProduct(0);

        getDropDowns();

        window.addEventListener('beforeprint', onBeforePrint);

        window.addEventListener('afterprint', onAfterPrint);

        return () => {
            window.removeEventListener('beforeprint', onBeforePrint);

            window.removeEventListener('afterprint', onAfterPrint);
        };
    }, []);

    return (
        <>

            <div className="row mt16 o_portal_sale_sidebar">
                <div className="col-12 col-lg justify-content-end">

                    <DivWithPreLoader isLoading={isLoading}>
                        <div className="card pb-5" id="portal_sale_content">
                            <div className="card-body">
                                <div>
                                    <div className="table-responsive calcBy-productCode" id="new_sale_order_table_parent">
                                        <table className="table table-sm table-sticky-header" id="new_sale_order_table">
                                            <thead className="bg-100">
                                                <tr>
                                                    <th className="text-left" style={{width: 'auto'}}>Product</th>
                                                    <th className="text-right" style={{width: "20%"}}>Quantity</th>
                                                    <th className="text-right" style={{width: "20%"}}>Amount</th>
                                                    <th className="text-right" style={{width: "5%"}}></th>
                                                </tr>
                                            </thead>
                                            <tbody onKeyDown={onKeyPressed}>
                                                {
                                                    orderData.products && orderData.products.length > 0 && focusedProductTd &&
                                                    orderData.products.map((product, productIndex) => {
                                                        let prodErrors = {};

                                                        if (orderFormErrors && orderFormErrors.products && typeof(orderFormErrors.products[productIndex]) != 'undefined') {
                                                            prodErrors = orderFormErrors.products[productIndex];
                                                        }

                                                        return (
                                                            <BillTableRow 
                                                                product={product}
                                                                productIndex={productIndex}
                                                                focusedProductTd={focusedProductTd}
                                                                currencySymbol={orderData.currencySymbol}
                                                                currencySymbolPosition={orderData.currencySymbolPosition}
                                                                tdOnFocusFn={onProductTdFocused}
                                                                deleteProductFn={onBtnDeleteProductClicked}
                                                                errors={prodErrors}
                                                            />
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                    {
                                        orderData.calculations &&
                                        <div className="row" style={{pageBreakInside: "avoid"}} id="new_sale_order_totals_table_parent">
                                            <div className="col-sm-7 col-md-6 col-lg-4 ml-auto">
                                                <BillCalculationsTable
                                                    calculations={orderData.calculations}
                                                    currencySymbol={orderData.currencySymbol}
                                                    currencySymbolPosition={orderData.currencySymbolPosition}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="row">
                                    <div className="col-8 col-lg-10">
                                        <table className="is-num-pad" border="0">
                                            <tbody>
                                                <tr>
                                                    <td><button className="btn btn-primary btn-numpad" data-key="7" tabIndex="-1" onClick={onNumpadBtnClicked}>7</button></td>
                                                    <td><button className="btn btn-primary btn-numpad" data-key="8" tabIndex="-1" onClick={onNumpadBtnClicked}>8</button></td>
                                                    <td><button className="btn btn-primary btn-numpad" data-key="9" tabIndex="-1" onClick={onNumpadBtnClicked}>9</button></td>
                                                    <td><button className="btn btn-success btn-numpad not-for-product" data-key="+" tabIndex="-1" onClick={onNumpadBtnClicked}>+</button></td>
                                                </tr>
                                                <tr>
                                                    <td><button className="btn btn-primary btn-numpad" data-key="4" tabIndex="-1" onClick={onNumpadBtnClicked}>4</button></td>
                                                    <td><button className="btn btn-primary btn-numpad" data-key="5" tabIndex="-1" onClick={onNumpadBtnClicked}>5</button></td>
                                                    <td><button className="btn btn-primary btn-numpad" data-key="6" tabIndex="-1" onClick={onNumpadBtnClicked}>6</button></td>
                                                    <td><button className="btn btn-danger btn-numpad not-for-product" data-key="-" tabIndex="-1" onClick={onNumpadBtnClicked}>&#x2013;</button></td>
                                                </tr>
                                                <tr>
                                                    <td><button className="btn btn-primary btn-numpad" data-key="1" tabIndex="-1" onClick={onNumpadBtnClicked}>1</button></td>
                                                    <td><button className="btn btn-primary btn-numpad" data-key="2" tabIndex="-1" onClick={onNumpadBtnClicked}>2</button></td>
                                                    <td><button className="btn btn-primary btn-numpad" data-key="3" tabIndex="-1" onClick={onNumpadBtnClicked}>3</button></td>
                                                    <td rowSpan="2">
                                                        <button className="btn btn-dark btn-numpad d-flex vh-align-center" data-key="enter" tabIndex="-1" onClick={onNumpadBtnClicked}>
                                                            <span className="rotate-90">ENTER</span>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><button className="btn btn-primary btn-numpad" data-key="0" tabIndex="-1" onClick={onNumpadBtnClicked}>0</button></td>
                                                    <td colSpan="2"><button className="btn btn-warning btn-numpad" data-key="delete,backspace" tabIndex="-1" onClick={onNumpadBtnClicked}>DEL</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="col-4 col-lg-2">
                                        <div className={"parent-custMobileNo " + (isFieldInvalid('customer_mobile_no') ? 'is-invalid' : '')}>
                                            <label className="col-form-label" htmlFor="custMobileNo"><strong>Customer contact</strong></label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="ic-text">+91</span>
                                                </div>

                                                <input type="tel" id="custMobileNo" name="custMobileNo" className="form-control is-mobile-number-field" value={orderData.customer_mobile_no} onChange={e => onOrderFieldChanged(e, 'customer_mobile_no')} />
                                            </div>
                                        </div>

                                        {
                                            ddPaymentModes && Object.keys(ddPaymentModes).length > 0 &&
                                            <div className={"payment_mode_field_parent mt-2 " + (isFieldInvalid('payment_mode') ? 'is-invalid' : '')}>
                                                <label><strong>Payment Mode</strong></label>
                                                {
                                                    Object.keys(ddPaymentModes).map((pm) => {
                                                        return (
                                                            <div className="custom-control custom-radio mt-2">
                                                                <input type="radio" name="paymentMode" className="custom-control-input" value={pm} id={"paymentMethod_" + pm} checked={orderData.payment_mode == pm} onChange={e => onOrderFieldChanged(e, 'payment_mode')} />
                                                                <label className="custom-control-label" htmlFor={"paymentMethod_" + pm}>{ddPaymentModes[pm]}</label>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        }

                                        <button className="btn btn-info btn-block mt-3" id="btnPrint" onClick={onBtnPrintClicked}>
                                            <i className="fa fa-print"></i>&#160;
                                            {
                                                orderData.orderId ? 'Confirm and Print' : 'Save and Print'
                                            }
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </DivWithPreLoader>

                </div>
            </div>

        </>
    );
}

function BillTableRow({productIndex, focusedProductTd, product, currencySymbol, currencySymbolPosition, tdOnFocusFn, deleteProductFn, errors = {}}) {
    return (
        <tr data-product-index={productIndex}>
            <td
                className={
                    "text-left is-editable " + (
                        (focusedProductTd.productIndex === productIndex && focusedProductTd.td === 'code') ? "is-focused" : ""
                    ) + " " + (
                        (errors && typeof(errors['code']) != 'undefined') ? "is-invalid" : ""
                    )
                }
                data-field-name="code"
                data-max-digits="3"
                tabIndex="0"
                onFocus={
                    (e) => tdOnFocusFn(e, productIndex, 'code')
                }>
                <span className="content">{product.name ?? product.code ?? ''}</span>
            </td>
            <td
                className={
                    "text-right is-editable " + (
                        (focusedProductTd.productIndex === productIndex && focusedProductTd.td === 'qty') ? "is-focused" : ""
                    ) + " " + (
                        (errors && typeof(errors['qty']) != 'undefined') ? "is-invalid" : ""
                    )
                }
                data-field-name="qty"
                tabIndex="0"
                onFocus={
                    (e) => tdOnFocusFn(e, productIndex, 'qty')
                }>
                <span className="content">{product.qty}</span>
            </td>
            <td className="text-right">
                { currencySymbolPosition === 'before' && (
                    <span dangerouslySetInnerHTML={{__html: currencySymbol}} />
                ) }
                <span className="content">
                    { product.unit_price ?? '0.00' }
                </span>
                { currencySymbolPosition === 'after' && (
                    <span dangerouslySetInnerHTML={{__html: currencySymbol}} />
                ) }
            </td>
            <td className="text-center">
                <Tooltip type="btn" className="text-danger" title="Delete" tabIndex="-1" onClick={e => deleteProductFn(e, productIndex)}>
                    <i className="fa fa-trash"></i>
                </Tooltip>
            </td>
        </tr>
    );
}

function BillCalculationsTable({calculations, currencySymbol, currencySymbolPosition}) {
    return (
        <table className="table table-sm">
            <tbody>
                {
                    calculations.map((calc) => {
                        return (
                            <tr>
                                {
                                    calc.bold ?
                                    <th className="text-left">{calc.name}</th>
                                    :
                                    <td className="text-left">{calc.name}</td>
                                }
                                <td className="text-right">
                                    { currencySymbolPosition === 'before' && (
                                        <span dangerouslySetInnerHTML={{__html: currencySymbol}} />
                                    ) }
                                    {calc.amount}
                                    { currencySymbolPosition === 'after' && (
                                        <span dangerouslySetInnerHTML={{__html: currencySymbol}} />
                                    ) }
                                </td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </table>
    );
}
