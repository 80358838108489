import React, { useEffect, useState } from "react";
import { useOutletContext, useParams ,useNavigate} from "react-router-dom";
import { RequestBackendAPI } from "../../../helpers/http";
import { toast } from "../../../helpers/viewHelper";
import CustomInputField from "../../../widgets/Fields/CustomInputField";
import SelectField from "../../../widgets/Fields/SelectField";

function AddCustomer() {
    const { setPageTitle } = useOutletContext();
    const navigate = useNavigate()
    const { id } = useParams();

    useEffect(() => {
        setPageTitle((id ? 'Edit' : 'Add') + ' Customer');

        if(id) {
            getCustomerInfo(id)
        } else {
            getCustomerDropDowns();
        }
    }, [id]);
    
    const initialFormData = {
        id: '',
        name: '',
        email: '',
        phone: '',
        street: '',
        street2: '',
        zip: '',
        state_id: '',
        country_id: '',
    };

    const [formData, setFormData] = useState({ ...initialFormData });
    const [formErrors, setFormErrors] = useState({});
    const [editMode, setEditMode] = useState(false);

    const [ ddStatesList, setDDStatesList ] = useState([]);
    const [ ddFilteredStatesList, setDDFilteredStatesList ] = useState([]);
    const [ ddCountriesList, setDDCountriesList ] = useState([]);

    const getCountryIdOfState = (stateId) => {
        let countryId;

        for (let i = 0; i < ddFilteredStatesList.length; i++) {
            if (ddFilteredStatesList[i].id == stateId) {
                countryId = ddFilteredStatesList[i].country;
                break;
            }
        }

        return countryId;
    };

    const isStateInChosenCountry = (formData) => {
        let countryId = getCountryIdOfState(formData.state_id);

        return countryId && Number(countryId) == Number(formData.country_id);
    };

    function handleInputChange(event) {
        let {name, value} = event.target;

        // if (name == 'state_id' || name == 'country_id') {
        //     value = event;
        //     /* if (event && typeof(event) == 'object' && event.id && event.name) {
        //         value = event.id;
        //     } */
        // } else {
        //     name = event.target.name;
        //     value = event.target.value;
        // }

        let newFormData = {
            ...formData,
            [name]: value
        };

        if (name === 'state_id') {
            if (newFormData.state_id) {
                let countryId = getCountryIdOfState(newFormData.state_id);

                if (countryId && countryId != newFormData.country_id) {
                    newFormData['country_id'] = countryId;
                }
            }
        } else if (name === 'country_id') {
            if (newFormData.country_id) {
                if (!isStateInChosenCountry(newFormData)) {
                    newFormData['state_id'] = '';
                }
            }
        }

        filterDDStatesList(newFormData, ddStatesList);
        setFormData(newFormData);
    }

    const filterDDStatesList = (formData, ddStatesList) => {
        let newDDFilteredStatesList = [];

        for (let i = 0; i < ddStatesList.length; i++) {
            if (!formData.country_id || ddStatesList[i].country == formData.country_id) {
                newDDFilteredStatesList.push({...ddStatesList[i]});
            }
        }

        setDDFilteredStatesList([...newDDFilteredStatesList]);
    };

    const handleNavigateCustomerList = () => {
        navigate("/admin/customers");
    };

    const handleAddCustomer = (e) => {
        e.preventDefault();
        submitCustomerForm(formData);
    };

    const submitCustomerForm = (submitData) => {
        let formData = {
            ...submitData,
            ...JSON.parse(JSON.stringify(submitData)),
            type: '',
        }

        RequestBackendAPI({
            path: 'customerMaster.save',
            method: 'POST',
            data: formData,
            callback: (success, response) => {
                if (success) {
                    setFormData({ ...initialFormData });
                    toast('success', response.message ?? 'customer add successfully.');
                    handleNavigateCustomerList()
                    setFormErrors({});
                    if (response.data) {
                        setEditMode(false);
                    }
                } else {
                    setFormErrors(response.errors || {});
                    toast('error', response.message ?? 'Unable to add customer');
                }
            }
        });
    };

    const setDropDownsFromResponse = (responseData, customersData) => {
        let newDDStatesList = {}, newDDCountriesList = {};

        if (responseData && responseData.dropDowns) {
            if (responseData.dropDowns.states && typeof(responseData.dropDowns.states) === 'object' && Array.isArray(responseData.dropDowns.states)) {
                newDDStatesList = [...responseData.dropDowns.states];
            }

            if (responseData.dropDowns.countries && typeof(responseData.dropDowns.countries) === 'object' && Array.isArray(responseData.dropDowns.countries)) {
                newDDCountriesList = [...responseData.dropDowns.countries];
            }
        }

        setDDStatesList([...newDDStatesList]);
        setDDFilteredStatesList([...newDDStatesList]);
        setDDCountriesList([...newDDCountriesList]);

        filterDDStatesList(customersData, newDDStatesList);
    };

    // getCustomerInfo
    const getCustomerInfo = (customerId) => {
        setFormData({...initialFormData});

        RequestBackendAPI({
            path: `customerMaster.edit`,
            pathReplaces: {
                'id': customerId,
            },
            method: 'POST',
            callback: (success, response) => {
                if (success) {
                    let CustomerData = {
                        ...response.data.customer
                    };

                    setDropDownsFromResponse(success && response && response.data ? response.data : {}, CustomerData);

                    setFormData({...CustomerData});
                    setEditMode(true);
                }else{
                    toast('error', response && response.message ? response.message : 'Unable to fetch customer data');

                    navigate('/admin/customers');
                }
            }
        });
    }

    const getCustomerDropDowns = () => {
        RequestBackendAPI({
            path: `customerMaster.dropDowns`,
            method: 'POST',
            callback: (success, response) => {
                if (success) {
                    setDropDownsFromResponse(success && response && response.data ? response.data : {}, {});
                } else {
                    toast('error', response && response.message ? response.message : 'Unable to fetch customer drop-downs');

                    navigate('/admin/customers');
                }
            }
        });
    };
    
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card p-2">
                        <div className="card-body">
                            <form onSubmit={handleAddCustomer}>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="name">Name</label>
                                            <input
                                                type="text"
                                                name="name"
                                                className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                                                value={formData.name}
                                                onChange={handleInputChange}
                                                autoFocus
                                            />
                                            {formErrors.name && <div className="invalid-feedback">{formErrors.name}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                type="text"
                                                name="email"
                                                className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                                                value={formData.email}
                                                onChange={handleInputChange}
                                            />
                                            {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="phone">Phone No</label>
                                            <CustomInputField
                                                name="phone"
                                                value={formData.phone}
                                                fieldType="phone"
                                                onChangeFn={handleInputChange}
                                                className={(formErrors.phone ? 'is-invalid' : '')} />
                                            {formErrors.phone && <div className="invalid-feedback">{formErrors.phone}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="email">Street</label>
                                            <input
                                                type="text"
                                                name="street"
                                                className={`form-control ${formErrors.street ? 'is-invalid' : ''}`}
                                                value={formData.street}
                                                onChange={handleInputChange}
                                            />
                                            {formErrors.street && <div className="invalid-feedback">{formErrors.street}</div>}
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="form-group mb-3">
                                            <label htmlFor="street2">Street 2</label>
                                            <input
                                                type="text"
                                                name="street2"
                                                className={`form-control ${formErrors.street2 ? 'is-invalid' : ''}`}
                                                value={formData.street2}
                                                onChange={handleInputChange}
                                            />
                                            {formErrors.street2 && <div className="invalid-feedback">{formErrors.street2}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="email">City</label>
                                            <input
                                                type="text"
                                                name="city"
                                                className={`form-control ${formErrors.city ? 'is-invalid' : ''}`}
                                                value={formData.city}
                                                onChange={handleInputChange}
                                            />
                                            {formErrors.city && <div className="invalid-feedback">{formErrors.city}</div>}
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="zip">PIN Code</label>
                                            <CustomInputField
                                                name="zip"
                                                value={formData.zip}
                                                fieldType="zip"
                                                onChangeFn={handleInputChange}
                                                className={(formErrors.zip ? 'is-invalid' : '')} />
                                            {formErrors.zip && <div className="invalid-feedback">{formErrors.zip}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <div className="form-group is-required-field">
                                            <label htmlFor="state_id">State</label>
                                            <SelectField
                                                name="state_id"
                                                value={formData.state_id}
                                                options={ddFilteredStatesList}
                                                optLabelField="name"
                                                optValueField="id"
                                                onChangeFn={value => handleInputChange(value, 'state_id')}
                                                className={(formErrors.state_id ? 'is-invalid' : '')} />
                                            {formErrors.state_id && <div className="invalid-feedback">{formErrors.state_id}</div>}
                                        </div>
                                    </div>

                                    <div class="col">
                                        <div className="form-group is-required-field">
                                            <label htmlFor="country_id">Country</label>
                                            <SelectField
                                                name="country_id"
                                                value={formData.country_id}
                                                options={ddCountriesList}
                                                optLabelField="name"
                                                optValueField="id"
                                                onChangeFn={value => handleInputChange(value, 'country_id')}
                                                className={(formErrors.country_id ? 'is-invalid' : '')} />
                                            {formErrors.country_id && <div className="invalid-feedback">{formErrors.country_id}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col d-flex justify-content-end">
                                        <button type="button" className="btn btn-default" onClick={handleNavigateCustomerList}>
                                            <i className="fas fa-angles-left"></i>&nbsp;Go Back
                                        </button>
                                    </div>

                                    <div className="col">
                                        <button type="submit" className="btn btn-primary">
                                            {formData.id ? 'Update' : 'Save'}
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddCustomer;
