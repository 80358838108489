import React, { useEffect, useRef, useState } from "react";
import { RequestBackendAPI } from "../../../helpers/http";

import { Link, useOutletContext ,useParams } from "react-router-dom";
import { toast } from "../../../helpers/viewHelper";
import Pagination from "../../../widgets/Pagination";
import Table from "../../../widgets/Table";
import { confirmDialog ,togglePreloader} from "../../../helpers/viewHelper";
import Tooltip from "../../../widgets/Tooltip";

function LocationList() {
    const { setPageTitle } = useOutletContext();
    const columns = [
        {
            'column': 'code',
            'label': 'Code',
        },
        {
            'column': 'name',
            'label': 'Name',
        },
        {
            'column': 'description',
            'label': 'Description',
        },
        {
            'column': 'type',
            'label': 'Location Type',
        },
    ];

    const tableRef = useRef(null);

    useEffect(() => {
        setPageTitle('Location');
    }, []);
    

    const handleLocationDelete = (location) => {
        let id = location.id;
        console.log("location id",id)
        togglePreloader('show');
        RequestBackendAPI({
            path: 'location.delete',
            pathReplaces: {
                'id': id,
            },
            method:'POST',
            callback:(success,response) => {
                let errMessage = 'Unable to Location Delete';

                if(success){
                    console.log("Delete Success")

                    if (tableRef.current) {
                        tableRef.current.refreshTable();
                    }

                    toast('success', ((response && response.message) ? response.message : 'Location Delete successfully.'));
                    togglePreloader('hide');
                }else{
                    if (response.data && response.data.message) {
                        errMessage = response.data.message.replace('\n', '<br>');
                    } else if (response.message) {
                        errMessage = response.message;
                    }
                    
                    toast('error', errMessage);
                    console.log("response Delete",response);
                    togglePreloader('hide');
                }
            }
        })
    }

    const locationDelateConfirmation = (e, location) => {
        e.preventDefault();

        e.target.blur();

        confirmDialog({
            message:'Are you sure you want to delete this location',
            confirmBtn:{
                onClick:() => {
                    handleLocationDelete(location)
                }
            },
            cancelBtn:{
                onClick:() => {

                }
            }
        })
    }

return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4 mb-3">
                                    <Link to={'/admin/locations/add'} className="btn btn-primary">
                                        <i className="fas fa-plus-circle"></i> Add Location
                                    </Link>
                                </div>
                            </div>

                            <Table
                                apiPath='location.list'
                                reqMethod='POST'
                                ref={tableRef}
                                dataKeyFromResponse='locations'
                                columns={columns}
                                actionColumnFn={(rowData) => {
                                    return (
                                        <>
                                            <Tooltip type="route" className="text-primary" to={`/admin/locations/edit/${rowData.id}`} title="Edit Location">
                                                <i className="fas fa-pencil"></i>
                                            </Tooltip>
                                            &nbsp;|&nbsp;
                                            <Tooltip type="btn" className="text-danger" onClick={(e) => locationDelateConfirmation(e, rowData)} title="Delete Location">
                                                <i className="fas fa-trash"></i>
                                            </Tooltip>
                                        </>
                                    );
                                }} />

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LocationList;
