import React from 'react';
import {
    BrowserRouter
} from 'react-router-dom';

import { AppRoutes } from './Routes';
import { AuthContextProvider } from './auth/authContext';

function App() {
    return (
        <AuthContextProvider>
            <BrowserRouter>
                <AppRoutes />
            </BrowserRouter>
        </AuthContextProvider>
    );
}

export default App;
