import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { AuthContext, AuthStates } from "./authContext";

import SplashScreen from '../pages/SplashScreen';
import { LocalDB } from "../helpers/localDB";
import { UnAuthenticatedRoutes } from "../Routes";

import ErrorPageLayout from "../pages/wrapper/ErrorPageLayout";
import ForbiddenErrorPage from "../pages/errors/ForbiddenErrorPage";

const defaultPostLoginPath = '/';

function userTypeBasedPostLoginPage(user, defaultPostLoginPath) {
    const adminInitialURL = '/';
    const storeUserInitialURL = '/orders/purchase-orders';
    const kitchenUserInitialURL = '/orders/manufacture-orders';
    const outletUserInitialURL = '/counter-user/bill';

    let postLoginPath = defaultPostLoginPath;

    if (user && user.suluva_pos_user_role && user.user_location_type) {
        if (user.suluva_pos_user_role == 'user') {
            if (user.user_location_type == 'store') {
                postLoginPath = storeUserInitialURL;
            }

            if (user.user_location_type == 'kitchen') {
                postLoginPath = kitchenUserInitialURL;
            }

            if (user.user_location_type == 'outlet') {
                postLoginPath = outletUserInitialURL;
            }
        } else if (user.suluva_pos_user_role == 'admin') {
            postLoginPath = adminInitialURL;
        }
    }

    return postLoginPath;
}

export function AuthenticatedRoute({children, userTypes = [], userLocationTypes = []}) {
    const routePath = useLocation();
    const { appStatus } = useContext(AuthContext);

    const user = LocalDB.getJSON('user');
    const postLoginPath = userTypeBasedPostLoginPage(user, defaultPostLoginPath);

    // This is temporary route to showcase odoo integration
    /* if (routePath.pathname == '/admin/locations' || routePath.pathname.startsWith('/admin/locations/')) {
        return <>{children}</>;
    } else {
        return <Navigate to={'/admin/locations'} />;
    } */

    if (!appStatus.isAuthenticated) {
        if (appStatus.state === AuthStates.LOADING) {
            if (postLoginPath !== routePath.pathname) {
                // Go to requested URL after authentication is done
                LocalDB.store('postLoginPath', routePath.pathname);
            }
        }

        if (UnAuthenticatedRoutes.indexOf(routePath) === -1) {
            return <Navigate to={'/auth/login'} />;
        }
    }

    let tempPostLoginPath = LocalDB.get('postLoginPath');

    if (tempPostLoginPath) {
        if (tempPostLoginPath !== routePath.pathname) {
            LocalDB.delete('postLoginPath');

            return <Navigate to={tempPostLoginPath} />;
        }
    }

    if (appStatus.state === AuthStates.LOADING) {
        return <SplashScreen />;
    }

    if (userTypes || userLocationTypes) {
        if (typeof(userTypes) === 'string') {
            userTypes = userTypes.split(',');
        }

        if (user && user.suluva_pos_user_role) {
            if (!userTypes.includes(user.suluva_pos_user_role)) {
                return (
                    <ErrorPageLayout>
                        <ForbiddenErrorPage />
                    </ErrorPageLayout>
                );
            }
        }

        if (typeof(userLocationTypes) === 'string') {
            userLocationTypes = userLocationTypes.split(',');
        }

        if (user && user.user_location_type) {
            if (!userLocationTypes.includes(user.user_location_type)) {
                return (
                    <ErrorPageLayout>
                        <ForbiddenErrorPage />
                    </ErrorPageLayout>
                );
            }
        }
    }

    return <>{children}</>;
}

export function UnAuthenticatedRoute({children}) {
    const routePath = useLocation();
    const { appStatus } = useContext(AuthContext);

    const user = LocalDB.getJSON('user');
    const postLoginPath = userTypeBasedPostLoginPage(user, defaultPostLoginPath);

    // This is temporary route to showcase odoo integration
    /* if (routePath.pathname == '/admin/locations' || routePath.pathname.startsWith('/admin/locations/')) {
        return <>{children}</>;
    } else {
        return <Navigate to={'/admin/locations'} />;
    } */

    if (appStatus.isAuthenticated) {
        return <Navigate to={postLoginPath} />;
    }

    if (appStatus.state === AuthStates.LOADING) {
        return <SplashScreen />
    }

    return <>{children}</>;
}
