import ErrorPageLayout from "../wrapper/ErrorPageLayout";

export default function ForbiddenErrorPage() {
    return (
        <ErrorPageLayout title="403" hideInPageTitle={true} forceFillHeight={true}>
            <div className="error-page">
                <h2 className="headline text-warning">403</h2>

                <div className="error-content">
                    <h3><i className="fas fa-exclamation-triangle text-warning"></i> Restricted Access</h3>
                    <p>
                        Sorry, You do not have sufficient permission to access this page.
                    </p>
                </div>
            </div>
        </ErrorPageLayout>
    );
}
