import { useEffect, useState } from "react";
import { LocalDB } from "../../helpers/localDB";

import BillingScreenLayout1 from "./BillingScreenLayout1";
import { useOutletContext } from "react-router-dom";
import Tooltip from "../../widgets/Tooltip";
import BillingScreenLayout2 from "./BillingScreenLayout2";

export default function BillingScreenWrapper() {
    const [ billScreenLayout, setBillScreenLayout ] = useState('');

    const { addClassToBodyElement, setExtraIcons, setPageTitle } = useOutletContext();

    useEffect(() => {
        // console.log('billScreenLayout:', billScreenLayout);

        setExtraIcons([
            <Tooltip type="btn" title={billScreenLayout == 'layout-1' ? 'Layout 2' : 'Layout 1'} className="nav-link" onClick={e => {
                e.preventDefault();

                document.activeElement.blur();

                setBillScreenLayout(billScreenLayout == 'layout-1' ? 'layout-2' : 'layout-1');

                LocalDB.store('billScreenLayout', billScreenLayout == 'layout-1' ? 'layout-2' : 'layout-1');
            }}>
                <i className={"fas fa-" + (billScreenLayout == 'layout-1' ? 'table-cells' : 'list')}></i>
            </Tooltip>
        ]);
    }, [billScreenLayout]);

    useEffect(() => {
        setPageTitle('Bill an Order');

        addClassToBodyElement('hide-on-print');

        // console.log('LocalDB.get(\'billScreenLayout\'):', LocalDB.get('billScreenLayout'));

        setBillScreenLayout(LocalDB.get('billScreenLayout') ?? 'layout-1');

        return () => {
            setExtraIcons([]);

            setPageTitle('');
        };
    }, []);

    return (
        <>

            {
                billScreenLayout == 'layout-1' &&
                <BillingScreenLayout1 />
            }
            {
                billScreenLayout == 'layout-2' &&
                <BillingScreenLayout2 />
            }

        </>
    );
}
