import { createContext, useEffect, useReducer } from "react";
import { LocalDB } from '../helpers/localDB';
import { RequestBackendAPI } from "../helpers/http";
import { toast } from "../helpers/viewHelper";

export const AuthStates = {
    'LOADING': 'loading',
    'INIT': 'initial',
    'LOGGED_IN': 'loggedIn',
};

const initialAppState = {
    state: AuthStates.LOADING,
    isAuthenticated: false,
};

export const AuthContext = createContext();

function AuthReducer(state, action) {
    let updatedState = {
        isAuthenticated: false,
    };

    switch(action.state) {
        case AuthStates.INIT:
            updatedState = {
                ...state,
                state: AuthStates.INIT,
                isAuthenticated: false,
            };
            break;
        case AuthStates.LOGGED_IN:
            updatedState = {
                ...state,
                state: AuthStates.LOGGED_IN,
                isAuthenticated: true,
            };
            break;
        default:
            updatedState = state;
    }

    // console.log('updatedState', updatedState);

    return updatedState;
}

export function AuthContextProvider({children}) {
    // Reducer will be called twice in DEVELOPMENT mode.
    // See: https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
    const [ appStatus, dispatchAppStatus ] = useReducer(AuthReducer, initialAppState);

    useEffect(() => {
        const user = LocalDB.getJSON('user');

        if (user && user.authToken) {
            RequestBackendAPI({
                path: 'auth.check',
                method: 'POST',
                data: {},
                callback: (success, response) => {
                    if (success && response.data && response.data.user) {
                        dispatchAppStatus({ state: AuthStates.LOGGED_IN });
                    } else {
                        let message = 'Session expired. Please login again to proceed.';

                        if (response) {
                            if (response.data && response.data.message) {
                                message = response.data.message;
                            } else if (response.message) {
                                message = response.message;
                            }
                        }

                        toast('error', message);

                        dispatchAppStatus({ state: AuthStates.INIT });
                    }
                },
            });
        } else {
            dispatchAppStatus({ state: AuthStates.INIT });
        }
    }, []);

    return <AuthContext.Provider value={{ appStatus, dispatchAppStatus }}>{children}</AuthContext.Provider>
}
