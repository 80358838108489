import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import Table from "../../../widgets/Table";
import { useEffect, useRef, useState } from "react";
import { RequestBackendAPI } from "../../../helpers/http";

export default function ProductStockUpdatesPage() {
    const navigate = useNavigate()
    const { state } = useLocation();

    const { id } = useParams();
    const { setPageTitle, user } = useOutletContext();

    const [ columns, setColumns ] = useState([]);

    const renderStockCountColumn = (rowData, column) => {
        return (
            <>
                {
                    column.column == 'stock_before_update' ? (
                        <span className="">{rowData.stock_before_update}</span>
                    ) : (
                        rowData.stock_after_update > rowData.stock_before_update ?
                        <span className=""><i className="fas fa-arrow-up-long text-success"></i>&nbsp;&nbsp;{rowData.stock_after_update}</span> :

                        rowData.stock_after_update < rowData.stock_before_update ?
                        <span className=""><i className="fas fa-arrow-down-long text-danger"></i>&nbsp;&nbsp;{rowData.stock_after_update}</span> :

                        <span className="">{rowData.stock_after_update}</span>
                    )
                }
            </>
        );
    };

    const tableRef = useRef(null);

    useEffect(() => {
        let pageTitle = 'Stock Updates';

        if (state && state.productName) {
            pageTitle = 'Stock Updates of ' + state.productName;
        }

        setPageTitle(pageTitle);

        if (user && user.suluva_pos_user_role === 'user') {
            setColumns([
                {
                    'column': 'created_at',
                    'label': 'Transferred at',
                    'width': '25%',
                },
                {
                    'column': 'stock_before_update',
                    'label': 'Stock count<br>(Before update)',
                    'align': 'right',
                    'width': '20%',
                    'renderFn': renderStockCountColumn,
                },
                {
                    'column': 'stock_after_update',
                    'label': 'Stock count<br>(After update)',
                    'align': 'right',
                    'width': '20%',
                    'renderFn': renderStockCountColumn,
                },
                {
                    'column': 'note',
                    'label': 'Note',
                    'width': '35%',
                },
            ]);
        } else {
            setColumns([
                {
                    'column': 'location',
                    'label': 'Location',
                    'width': '15%',
                },
                {
                    'column': 'stock_before_update',
                    'label': 'Stock count<br>(Before update)',
                    'align': 'right',
                    'width': '15%',
                    'renderFn': renderStockCountColumn,
                },
                {
                    'column': 'stock_after_update',
                    'label': 'Stock count<br>(After update)',
                    'align': 'right',
                    'width': '15%',
                    'renderFn': renderStockCountColumn,
                },
                {
                    'column': 'created_at',
                    'label': 'Transferred at',
                    'width': '15%',
                },
                {
                    'column': 'note',
                    'label': 'Note',
                    'width': '40%',
                },
            ]);
        }
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <Table
                                apiPath='productMaster.stockUpdates'
                                apiPathReplaces={{
                                    'id': id,
                                }}
                                reqMethod='POST'
                                ref={tableRef}
                                dataKeyFromResponse='updates'
                                columns={columns}
                                actionColumnFn={false} />

                            <div className="row mt-3">
                                <div className="col d-flex justify-content-center">
                                    <button type="button" className="btn btn-default" onClick={() => {
                                        if (state && state.backURL) {
                                            navigate(state.backURL);
                                        } else {
                                            navigate('/admin/products');
                                        }
                                    }}>
                                        <i className="fas fa-angles-left"></i>&nbsp;Go Back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}