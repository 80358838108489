import {
    useRoutes
} from 'react-router-dom';

// Import Auth Guard
import { AuthenticatedRoute, UnAuthenticatedRoute } from './auth/authGuard';

// Import Page Layouts
import AuthenticatedPageLayout from './pages/wrapper/AuthenticatedPageLayout';
import UnAuthenticatedPageLayout from './pages/wrapper/UnAuthenticatedPageLayout';

// Import Pages
import NotFoundPage from './pages/errors/NotFoundPage';
import ForbiddenErrorPage from './pages/errors/ForbiddenErrorPage';

import Dashboard from './pages/Dashboard';
import LoginPage from './pages/LoginPage';
import UsersList from './pages/masters/user/ListUsers';
import AddUser from './pages/masters/user/AddUser';
import LocationList from './pages/masters/location/ListLocation';
import AddLocation from './pages/masters/location/AddLocation';
import ProductList from './pages/masters/products/ListProducts';
import AddProduct from './pages/masters/products/AddProducts';
import CustomersList from './pages/masters/customers/ListCustomers';
import AddCustomer from './pages/masters/customers/AddCustomer';
import VendorList from './pages/masters/vendors/ListVendors';
import AddVendor from './pages/masters/vendors/AddVendor';
import PurchaseOrderList from './pages/masters/purchasesOrder/ListPurchaseOrder';
import AddPurchaseOrder from './pages/masters/purchasesOrder/AddPurchaseOrder';

import SaleOrdersList from './pages/orders/saleOrders/ListSaleOrders'
import AddSaleOrder from './pages/orders/saleOrders/AddSaleOrder';

import SaleOrderHistoryPage from './pages/orders/history/ListOrderHistory';
import ProductStockUpdatesPage from './pages/masters/products/ProductStockUpdatesPage';
import ManufactureOrderList from './pages/masters/manufactureOrder/ManufactureOrderList';
import AddManufactureOrder from './pages/masters/manufactureOrder/AddManufactureOrder';
import StockHistoryPage from './pages/masters/products/StockHistoryPage';
import BillingScreenWrapper from './pages/Billing/BillingScreenWrapper';
import ProductStockList from './pages/masters/products/ProductStockList';

export const AuthenticatedRoutes = Object.freeze([
    '/',
]);

export const UnAuthenticatedRoutes = Object.freeze([
    '/auth/login',
    '/auth/forgot-password',
    '/auth/reset-password',
]);

export function AppRoutes() {
    let saleOrderRoutes = {
        path: 'sale-orders',
        children: [
            {
                path: '',
                element: <SaleOrdersList />,
            },
            {
                path: 'add',
                element: <AddSaleOrder />
            },
            {
                path: 'edit/:id',
                element: <AddSaleOrder />
            },
            {
                path: ':method/:id',
                element:<AddSaleOrder />
            }
        ],
    };

    let purchaseOrderRoutes = {
        path: 'purchase-orders',
        children: [
            {
                path: '',
                element: <PurchaseOrderList />,
            },
            {
                path: 'add',
                element: <AddPurchaseOrder />,
            },
            {
                path: 'edit/:id',
                element: <AddPurchaseOrder />,
            },
        ]
    };

    const manufactureOrderRoutes = {
        path: 'manufacture-orders',
        children: [
            {
                path: '',
                element: <ManufactureOrderList />,
            },
            {
                path: 'add',
                element: <AddManufactureOrder />,
            },
            {
                path: 'edit/:id',
                element: <AddManufactureOrder />,
            },
        ]
    };

    let orderHistoryRoutes = {
        path: 'history',
        children: [
            {
                path: 'orders',
                element: <SaleOrderHistoryPage />,
            },

            {
                path: 'product-stock',
                element: <StockHistoryPage />,
            },
        ],
    };

    const routes = useRoutes([
        {
            element: (
                <AuthenticatedRoute userTypes={['admin']}>
                    <AuthenticatedPageLayout />
                </AuthenticatedRoute>
            ),
            children: [
                {
                    path: '/',
                    element: <Dashboard />,
                },
                {
                    path: '/admin',
                    children: [
                        {
                            path: 'users',
                            element: <UsersList />,
                        },
                        {
                            path: 'users/add',
                            element: <AddUser />,
                        },
                        {
                            path: 'users/edit/:id',
                            element: <AddUser />,
                        },
                        {
                            path: 'locations',
                            element: <LocationList />,
                        },
                        {
                            path: 'locations/add',
                            element: <AddLocation />,
                        },
                        {
                            path: 'locations/edit/:id',
                            element: <AddLocation />,
                        },
                        {
                            path: 'products',
                            element: <ProductList />,
                        },
                        {
                            path: 'products/add',
                            element: <AddProduct />,
                        },
                        {
                            path: 'products/edit/:id',
                            element: <AddProduct />,
                        },
                        {
                            path: 'customers',
                            children: [
                                {
                                    path: '',
                                    element: <CustomersList />,
                                },
                                {
                                    path: 'add',
                                    element: <AddCustomer />,
                                },
                                {
                                    path: 'edit/:id',
                                    element: <AddCustomer />
                                }
                            ],
                        },
                        {
                            path: 'vendors',
                            children: [
                                {
                                    path: '',
                                    element: <VendorList />,
                                },
                                {
                                    path: 'add',
                                    element: <AddVendor />
                                },
                                {
                                    path: 'edit/:id',
                                    element: <AddVendor />
                                }
                            ],
                        },
                    ],
                },
            ]
        },

        {
            element: (
                <AuthenticatedRoute userTypes={['user']} userLocationTypes={['outlet']}>
                    <AuthenticatedPageLayout />
                </AuthenticatedRoute>
            ),
            children: [
                {
                    path: '/counter-user',
                    children: [
                        {
                            path: 'bill',
                            element: <BillingScreenWrapper />,
                        },
                    ],
                },
            ],
        },

        {
            path: 'orders',
            children: [
                {
                    element: (
                        <AuthenticatedRoute userTypes={['admin', 'user']} userLocationTypes={['outlet']}>
                            <AuthenticatedPageLayout />
                        </AuthenticatedRoute>
                    ),
                    children: [
                        {
                            ...saleOrderRoutes,
                        }
                    ],
                },

                {
                    element: (
                        <AuthenticatedRoute userTypes={['admin', 'user']} userLocationTypes={['kitchen', 'outlet']}>
                            <AuthenticatedPageLayout />
                        </AuthenticatedRoute>
                    ),
                    children: [
                        {
                            ...manufactureOrderRoutes,
                        }
                    ],
                },

                {
                    element: (
                        <AuthenticatedRoute userTypes={['admin', 'user']} userLocationTypes={['store', 'kitchen']}>
                            <AuthenticatedPageLayout />
                        </AuthenticatedRoute>
                    ),
                    children: [
                        {
                            ...purchaseOrderRoutes,
                        }
                    ],
                },
            ],
        },

        {
            element: (
                <AuthenticatedRoute userTypes={['admin', 'user']} userLocationTypes={['store', 'kitchen', 'outlet']}>
                    <AuthenticatedPageLayout />
                </AuthenticatedRoute>
            ),
            children: [
                {
                    ...orderHistoryRoutes,
                },
                {
                    path: '/products/stock',
                    element: <ProductStockList />,
                },
                {
                    path: 'products/:id/stock-updates',
                    element: <ProductStockUpdatesPage />,
                },
            ],
        },

        {
            element: (
                <UnAuthenticatedRoute>
                    <UnAuthenticatedPageLayout />
                </UnAuthenticatedRoute>
            ),
            children: [
                {
                    path: '/auth',
                    children: [
                        {
                            path: 'login',
                            element: <LoginPage />,
                        },
                    ],
                },
            ]
        },

        {
            path: '403',
            element: <ForbiddenErrorPage />
        },

        {
            path: '*',
            element: <NotFoundPage />,
        },
    ]);

    return routes;
}
