import React, { useEffect, useState } from "react";
import { useOutletContext, useParams ,useNavigate} from "react-router-dom";
import { RequestBackendAPI } from "../../../helpers/http";
import { toast , togglePreloader} from "../../../helpers/viewHelper";
import CustomInputField from "../../../widgets/Fields/CustomInputField";
import SelectField from "../../../widgets/Fields/SelectField";
import Tooltip from "../../../widgets/Tooltip";

function AddUser() {
    const { setPageTitle } = useOutletContext();
    const navigate = useNavigate()
    const { id } = useParams();

    useEffect(() => {
        setPageTitle((id ? 'Edit' : 'Add') + ' User');

        if(id){
            getUserInfo(id)
        } else {
            getUserRoleDropDowns();
        }
    }, [id]);

    const initialFormData = {
        id: '',
        username: '',
        full_name: '',
        email: '',
        phone: '',
        user_role: '',
        user_location_type: '',
        location: '',
        password: '',
        password_confirm: '',
    };

    const [formData, setFormData] = useState({ ...initialFormData });
    const [formErrors, setFormErrors] = useState({});
    const [editMode, setEditMode] = useState(false);

    const [ddUserRoles, setDDUserRoles] = useState({});
    const [ddUserLocationTypes, setDDUserLocationTypes] = useState({});
    const [ddLocationsList, setDDLocationsList] = useState({});

    const [ isPasswordVisible, togglePasswordVisibility ] = useState(false);

    function handleInputChange(event) {
        const { name, value } = event.target;

        setFormData({
            ...formData,
            [name]: value
        });

        if (name === 'user_location_type') {
            getUserRoleDropDowns('locations', {
                'location_type': value
            });
        }
    }
    
    const handleNavigateUserList = () => {
        navigate("/admin/users");
    };

    const setDropDownsFromResponse = (dataNeeded, responseData) => {
        let newUserRoles = {}, newLocationTypes = {}, newLocations ={};

        if (responseData && responseData.dropDowns) {
            const dropDowns = responseData.dropDowns;

            if (dropDowns.userRoles && typeof(dropDowns.userRoles) == 'object' && !(dropDowns.userRoles instanceof Array)) {
                newUserRoles = {...dropDowns.userRoles};
            }

            if (dropDowns.userLocationTypes && typeof(dropDowns.userLocationTypes) == 'object' && !(dropDowns.userLocationTypes instanceof Array)) {
                newLocationTypes = {...dropDowns.userLocationTypes};
            }

            if (dropDowns.locations && typeof(dropDowns.locations) == 'object' && !(dropDowns.locations instanceof Array)) {
                newLocations = {...dropDowns.locations};
            }
        }

        if (dataNeeded == 'all' || dataNeeded == 'initial') {
            setDDUserRoles({...newUserRoles});
            setDDUserLocationTypes({...newLocationTypes});
        }

        if (dataNeeded == 'all' || dataNeeded == 'locations') {
            setDDLocationsList({...newLocations});
        }
    };

    const getUserRoleDropDowns = (dataNeeded = 'initial', filters = {}) => {
        if (!filters) {
            if (dataNeeded === 'locations') {
                filters = {
                    'location_type': formData.user_location_type ?? '',
                };
            }
        }

        RequestBackendAPI({
            path: 'usersMaster.dropDowns',
            method: 'POST',
            data: {
                'dataNeeded': dataNeeded,
                'filters': {
                    ...(filters ?? {}),
                },
            },
            callback: (success, response) => {
                setDropDownsFromResponse(dataNeeded, success && response && response.data ? response.data : {});
            }
        });
    }

    const handleAddUser = (e) => {
        e.preventDefault();
        submitUserForm(formData);
    };

    const submitUserForm = (submitData) => {
        let formData = {
            ...submitData,
            ...JSON.parse(JSON.stringify(submitData)),
        }
        togglePreloader('show');
        RequestBackendAPI({
            path: 'usersMaster.save',
            method: 'POST',
            data: formData,
            callback: (success, response) => {
                if (success) {
                    setFormData({ ...initialFormData });
                    toast('success', response.message ?? 'User add successfully.');
                    handleNavigateUserList()
                    setFormErrors({});
                    if (response.data) {
                        setEditMode(false);
                    }
                    togglePreloader('hide');
                } else {
                    setFormErrors(response.errors || {});
                    toast('error', response.message ?? 'Unable to add user');
                    togglePreloader('hide');
                }
            }
        });
    };

    // HandleEditUser
    const getUserInfo = (userId) => {
        setFormData('');

        RequestBackendAPI({
            path: 'usersMaster.edit',
            pathReplaces: {
                'id': userId,
            },
            method: 'POST',
            callback: (success, response) => {
                if (success) {
                    let userData = {
                        ...(response && response.data && response.data.user ? response.data.user : {})
                    }; 

                    if (userData) {
                        setFormData({...userData})

                        setEditMode(true);
                    } else {
                        toast('error', 'Unable to get the User information from Server.');

                        navigate("/admin/users");
                    }
                }else{
                    toast('error', response && response.message ? response.message : 'Unable to fetch user data');
                }

                setDropDownsFromResponse('all', success && response && response.data ? response.data : {});
            }
        });
    }
    // HandleEditUser

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card p-2">
                        <div className="card-body">
                            <form onSubmit={handleAddUser}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="username">Username</label>
                                            <input
                                                type="text"
                                                name="username"
                                                className={`form-control ${formErrors.username ? 'is-invalid' : ''}`}
                                                value={formData.username ?? ''}
                                                onChange={handleInputChange}
                                                autoComplete="off"
                                            />
                                            {formErrors.username && <div className="invalid-feedback">{formErrors.username}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div className="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="full_name">Full Name</label>
                                            <input
                                                type="text"
                                                name="full_name"
                                                className={`form-control ${formErrors.full_name ? 'is-invalid' : ''}`}
                                                value={formData.full_name ?? ''}
                                                onChange={handleInputChange}
                                                autoComplete="off"
                                            />
                                            {formErrors.full_name && <div className="invalid-feedback">{formErrors.full_name}</div>}
                                        </div>
                                    </div>

                                    <div class="col">
                                        <div className="form-group is-required-field">
                                            <label htmlFor="user_role">Role</label>
                                            <SelectField
                                                name="user_role"
                                                value={formData.user_role}
                                                options={ddUserRoles}
                                                onChangeFn={handleInputChange}
                                                className={(formErrors.user_role ? 'is-invalid' : '')} />
                                            {formErrors.user_role && <div className="invalid-feedback">{formErrors.user_role}</div>}
                                        </div>
                                    </div>
                                </div>

                                {
                                    formData.user_role === 'user' &&
                                    <div class="row">
                                        <div class="col">
                                            <div className="form-group is-required-field">
                                                <label htmlFor="user_location_type">Location Type</label>
                                                <SelectField
                                                    name="user_location_type"
                                                    value={formData.user_location_type}
                                                    options={ddUserLocationTypes}
                                                    onChangeFn={handleInputChange}
                                                className={(formErrors.user_location_type ? 'is-invalid' : '')} />
                                                {formErrors.user_location_type && <div className="invalid-feedback">{formErrors.user_location_type}</div>}
                                            </div>
                                        </div>

                                        <div class="col">
                                            <div className="form-group is-required-field">
                                                <label htmlFor="location">Location</label>
                                                <SelectField
                                                    name="location"
                                                    value={formData.location}
                                                    options={ddLocationsList}
                                                    onChangeFn={handleInputChange}
                                                className={(formErrors.location ? 'is-invalid' : '')} />
                                                {formErrors.location && <div className="invalid-feedback">{formErrors.location}</div>}
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="row">
                                    <div className="col">
                                        <div className="form-group mb-3">
                                            <label htmlFor="phone">Phone No</label>
                                            <CustomInputField
                                                name="phone"
                                                value={formData.phone}
                                                fieldType="phone"
                                                onChangeFn={handleInputChange}
                                                className={(formErrors.phone ? 'is-invalid' : '')} />
                                            {formErrors.phone && <div className="invalid-feedback">{formErrors.phone}</div>}
                                        </div>
                                    </div>


                                    <div className="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                type="email"
                                                name="email"
                                                className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                                                value={formData.email ?? ''}
                                                onChange={handleInputChange}
                                                autoComplete="off"
                                            />
                                            {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    {
                                        formData.id ? null :
                                    <>
                                        <div className="col">
                                            <div className="form-group mb-3 is-required-field">
                                                <label htmlFor="password">Password</label>
                                                <div className="input-group">
                                                    <input
                                                        type={isPasswordVisible ? "text" : "password"}
                                                        name="password"
                                                        className={`form-control ${formErrors.password ? 'is-invalid' : ''}`}
                                                        value={formData.password ?? ''}
                                                        onChange={handleInputChange}
                                                        autoComplete="new-password"
                                                    />
                                                    <Tooltip
                                                        type="btn"
                                                        className="input-group-prepend"
                                                        onClick={e => togglePasswordVisibility(!isPasswordVisible)}
                                                        title={(isPasswordVisible ? 'Hide' : 'Show') + ' Password'}
                                                        tabIndex="-1"
                                                    >
                                                        <div className="input-group-text">
                                                            <span className={"fas fa-" + (isPasswordVisible ? "eye-slash" : "eye")}></span>
                                                        </div>
                                                    </Tooltip>
                                                    {formErrors.password && <div className="invalid-feedback">{formErrors.password}</div>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="form-group mb-3 is-required-field">
                                                <label htmlFor="password_confirm">Confirm Password</label>
                                                <div className="input-group">
                                                    <input
                                                        type={isPasswordVisible ? "text" : "password"}
                                                        name="password_confirm"
                                                        className={`form-control ${formErrors.password_confirm ? 'is-invalid' : ''}`}
                                                        value={formData.password_confirm ?? ''}
                                                        onChange={handleInputChange}
                                                        autoComplete="new-password"
                                                    />
                                                    <Tooltip
                                                        type="btn"
                                                        className="input-group-prepend"
                                                        onClick={e => togglePasswordVisibility(!isPasswordVisible)}
                                                        title={(isPasswordVisible ? 'Hide' : 'Show') + ' Password'}
                                                        tabIndex="-1"
                                                    >
                                                        <div className="input-group-text">
                                                            <span className={"fas fa-" + (isPasswordVisible ? "eye-slash" : "eye")}></span>
                                                        </div>
                                                    </Tooltip>
                                                    {formErrors.password_confirm && <div className="invalid-feedback">{formErrors.password_confirm}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    }
                                </div>

                                <div className="row">
                                    <div className="col d-flex justify-content-end">
                                        <button type="button" className="btn btn-default" onClick={handleNavigateUserList}>
                                            <i className="fas fa-angles-left"></i>&nbsp;Go Back
                                        </button>
                                    </div>

                                    <div className="col">
                                        <button type="submit" className="btn btn-primary">
                                            {formData.id ? 'Update' : 'Save'}
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddUser;
