import React, { useEffect, useState } from "react";
import { useOutletContext, useParams ,useNavigate, useSearchParams, useLocation} from "react-router-dom";
import { RequestBackendAPI } from "../../../helpers/http";
import { toast } from "../../../helpers/viewHelper";
import { event, param,$ } from "jquery";
import SelectField from "../../../widgets/Fields/SelectField";
import CustomInputField from "../../../widgets/Fields/CustomInputField";

function AddSaleOrder() {
    const { user, setPageTitle } = useOutletContext();
    const navigate = useNavigate();
    const { id, method } = useParams();
    const location = useLocation();

    useEffect(() => {
        setPageTitle((id && method ? 'View':id ? 'Edit' :'Add') + ' Orders');

        if (id) {
            getOrderInfo(id);
        } else {
            addRow();

            getOrderDropDowns();

            if (user && user.suluva_pos_user_role === 'user' && user.location) {
                setFormData({
                    ...formData,
                    'location': user.location.id,
                });

                getOrderDropDowns('products', {
                    'location': user.location.id,
                });
            }
        }
    }, [id]);

    const pricesInitialFormData = [{
        product: '',
        product_count: '',
        subtotal: '',
        unit_price:'',
        tax_id: '',   
    }];

    const initialFormData = {
        id: '',
        order_no:'',
        location: '',
        customer: '',
        subtotal: '',
        tax_total: '',
        grand_total: '',
        payment_mode: '',
    };

    const [formData, setFormData] = useState({ ...initialFormData });

    const [formDataProduct, setFormDataProduct] = useState([]);

    const [formErrors, setFormErrors] = useState({});
    const [editMode, setEditMode] = useState(false);

    const [ ddLocations, setDDLocationsList ] = useState({});
    const [ ddCustomers, setDDCustomersList ] = useState({});
    const [ ddPaymentModes, setDDPaymentModesList ] = useState({});
    const [ ddProducts, setDDProductsList ] = useState({});

    const [taxesList, setTaxesList] = useState({});

    const onOrderInfoChanged = (event) => {
        const { name, value } = event.target;

        setFormData({
            ...formData,
            [name]: value,
        });

        if (name === 'location') {
            getOrderDropDowns('products', {
                'location': value,
            });
        }
    };

    const onProductFieldChanged = (event, productIndex) => {
        const { name, value } = event.target;

        let orderProductDetails = [...formDataProduct];

        orderProductDetails[productIndex][name] = value;

        console.log('orderProductDetails[productIndex]:', orderProductDetails[productIndex]);

        let productId = orderProductDetails[productIndex].product;
        let count = orderProductDetails[productIndex].product_count;

        if (productId && ddProducts && productId in ddProducts && ddProducts[productId]) {
            let unitPrice = ddProducts[productId].unit_price;
            console.log('unitPrice:', unitPrice);

            if (!isNaN(Number(count)) && !isNaN(Number(unitPrice))) {
                orderProductDetails[productIndex].subtotal = Number(count) * Number(unitPrice);
            }
        }

        let orderSubTotal = 0,
            orderTaxTotal = 0;

        for (let i = 0; i < orderProductDetails.length; i++) {
            let itemSubTotal = 0, itemTaxTotal = 0;

            if (orderProductDetails[i].subtotal && !isNaN(Number(orderProductDetails[i].subtotal))) {
                itemSubTotal = Number(orderProductDetails[i].subtotal);
            }

            let taxId = orderProductDetails[i].tax_id;
            // console.log('taxId:', taxId, taxesList);

            if (taxId && taxesList && taxId in taxesList && taxesList[taxId]) {
                // console.log('taxesList[taxId]:', taxesList[taxId]);
                if (taxesList[taxId].percent) {
                    itemTaxTotal = itemSubTotal * taxesList[taxId].percent/100;
                }
            }

            orderSubTotal += itemSubTotal;
            orderTaxTotal += itemTaxTotal;
        }

        setFormDataProduct([...orderProductDetails]);

        setFormData({
            ...formData,
            subtotal: orderSubTotal,
            tax_total: orderTaxTotal,
            grand_total: (orderSubTotal + orderTaxTotal),
        });
    }

    const handleOrderCalculation =() => {
        let sub_total=0
        let tax_total=0
        let grand_total = 0

        formDataProduct.map((product) =>{
            sub_total += product.subtotal
            tax_total += product.tax_id
        });
        grand_total = sub_total + tax_total

        setFormData({
            ...formData,
            subtotal: sub_total,
            tax_total: tax_total,
            grand_total: grand_total,
            orderDetails: formDataProduct
        })
    }

    const handleNavigateProductList = () => {
        let goBackUrl = false;

        if (location && location.search) {
            goBackUrl = (new URLSearchParams(location.search)).get('backURL');
        }

        if (goBackUrl) {
            try {
                if (btoa(atob(goBackUrl)) == goBackUrl) {
                    goBackUrl = atob(goBackUrl);
                }
            } catch (err) {
                goBackUrl = false;
            }
        }

        if (goBackUrl) {
            navigate(goBackUrl);
        } else {
            navigate("/orders/sale-orders");
        }
    };

    const setDropDownsFromResponse = (dataNeeded, responseData) => {
        let newLocations = {};
        let newCustomers = {};
        let newPaymentMode = {};
        let newProducts = {};
        let newTaxes = {};


        if (responseData && responseData.dropDowns) {
            const dropDowns = responseData.dropDowns;

            if (dropDowns.locations && typeof(dropDowns.locations) == 'object' && !(dropDowns.locations instanceof Array)) {
                newLocations = {...dropDowns.locations};
            }

            if (dropDowns.customers && typeof(dropDowns.customers) == 'object' && !(dropDowns.customers instanceof Array)) {
                newCustomers = {...dropDowns.customers};
            } 

            if (dropDowns.paymentModes && typeof(dropDowns.paymentModes) == 'object' && !(dropDowns.paymentModes instanceof Array)) {
                newPaymentMode = {...dropDowns.paymentModes};
            }

            if (dropDowns.products && typeof(dropDowns.products) == 'object' && !(dropDowns.products instanceof Array)) {
                newProducts = {...dropDowns.products};
            }

            if (dropDowns.taxes && typeof(dropDowns.taxes) == 'object' && !(dropDowns.taxes instanceof Array)) {
                newTaxes = {...dropDowns.taxes};
            }

        }

        if (dataNeeded == 'all' || dataNeeded == 'initial') {
            setDDLocationsList({...newLocations});
            setDDCustomersList({...newCustomers});
            setDDPaymentModesList({...newPaymentMode});

            setTaxesList({...newTaxes});

            if (newTaxes && Object.keys(newTaxes).length == 1) {
                setFormDataProduct(prevProducts => {
                    let newOrderLines = [
                        ...prevProducts,
                    ];

                    if (newOrderLines) {
                        for (let i = 0; i < newOrderLines.length; i++) {
                            if (!newOrderLines[i].tax_id) {
                                newOrderLines[i].tax_id = newTaxes[Object.keys(newTaxes)[0]].id;
                            }
                        }
                    }

                    return [
                        ...newOrderLines,
                    ];
                });
            }
        }

        if (dataNeeded == 'all' || dataNeeded == 'products') {
            setDDProductsList({...newProducts});
        }
    }

    const getOrderDropDowns = (dataNeeded = 'initial', filters = {}) => {
        RequestBackendAPI({
            path: 'saleOrder.dropDowns',
            method: 'POST',
            data: {
                'dataNeeded': dataNeeded,
                'filters': filters,
            },
            callback: (success, response) => {
                setDropDownsFromResponse(dataNeeded, success && response && response.data ? response.data : {});
            }
        });
    }

    const onOrderFormSubmitted = (e) => {
        e.preventDefault();
        submitOrderForm();
    };

    const submitOrderForm = () => {
        let reqData = {
            ...formData,
            'order_details': [...formDataProduct],
        }

        RequestBackendAPI({
            path: 'saleOrder.save',
            method: 'POST',
            data: reqData,
            callback: (success, response) => {
                if (success) {
                    // setFormData({ ...initialFormData });

                    // setFormDataProduct([...pricesInitialFormData]);

                    toast('success', response.message ?? 'order saved successfully.');
                    handleNavigateProductList()
                    /* setFormErrors({});
                    if (response.data) {
                        setEditMode(false);
                    } */
                } else {
                    // console.log("response",response)
                    setFormErrors(response && response.errors ? response.errors : {});
                    toast('error', response && response.message ? response.message : 'Unable to save order');
                }
            }
        });
    };

    // HandleEditUser
    const getOrderInfo = (orderId) => {
        setFormData('');

        RequestBackendAPI({
            path: 'saleOrder.edit',
            pathReplaces: {
                'id': orderId
            },
            method: 'POST',
            callback: (success, response) => {
                if (success  && response && response.data && response.data.saleOrder) {

                    let orderData = {
                        ...response.data.saleOrder
                    };

                    // formData
                    setFormData({...orderData})

                    // pricesInitialFormData

                    setFormDataProduct([...(response.data.saleOrder.order_details ?? [])]);

                    setEditMode(true);
                } else {
                    toast('error', response.message ?? 'Unable to fetch product data');
                }

                setDropDownsFromResponse('all', success && response && response.data ? response.data : {});
            }
        });
    }
    // HandleEditUser

    // Add a new row
    const addRow = () => {
        setFormDataProduct([
            ...formDataProduct,
            {
                product: '',
                product_count: '',
                subtotal: '',
                unit_price:'',
                tax_id: (taxesList && taxesList.length == 1 ? taxesList[0].id : '')
            }
        ]);
    };

    // Delete a row by index
    const deleteRow = (index) => {
        setFormDataProduct(formDataProduct.filter((_, i) => i !== index));
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card p-2">
                        <div className="card-header">
                            <h5>Sale Order</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={onOrderFormSubmitted}>
                                {id &&
                                <>
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="order_no">Order No</label>
                                                <input 
                                                    className="form-control order_no"
                                                    name="order_no"
                                                    value={formData.order_no}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                    
                                }
                                <div className="row">
                                    <div className="col">
                                        <div className="form-group is-required-field">
                                            <label htmlFor="location">Location</label>
                                            <SelectField
                                                name="location"
                                                value={formData.location}
                                                options={ddLocations}
                                                onChangeFn={onOrderInfoChanged}
                                                className={(formErrors.location ? 'is-invalid' : '')}
                                                disabled = {method === 'view' || (user && user.suluva_pos_user_role === 'user' && user.location) || (formData.id)} />
                                            {formErrors.location && <div className="invalid-feedback">{formErrors.location}</div>}
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="form-group is-required-field">
                                            <label htmlFor="customer">Customer</label>
                                            <SelectField
                                                name="customer"
                                                value={formData.customer}
                                                options={ddCustomers}
                                                onChangeFn={onOrderInfoChanged}
                                                className={(formErrors.customer ? 'is-invalid' : '')}
                                                disabled = {method === 'view'} />
                                            {formErrors.customer && <div className="invalid-feedback">{formErrors.customer}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group is-required-field">
                                            <label htmlFor="payment_mode">Payment Mode</label>
                                            <SelectField
                                                name="payment_mode"
                                                value={formData.payment_mode}
                                                options={ddPaymentModes}
                                                onChangeFn={onOrderInfoChanged}
                                                className={(formErrors.payment_mode ? 'is-invalid' : '')}
                                                disabled = {method === 'view'} />
                                            {formErrors.payment_mode && <div className="invalid-feedback">{formErrors.payment_mode}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="subtotal">Sub Total</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="subtotal"
                                                value={formData.subtotal}
                                                disabled
                                            />
                                        </div>
                                        {formErrors.sub_total && <div className="invalid-feedback">{formErrors.sub_total}</div>}
                                    </div>

                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="tax_total">Tax Total</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="tax_total"
                                                value={formData.tax_total}
                                                disabled
                                            />
                                        </div>
                                        {formErrors.tax_total && <div className="invalid-feedback">{formErrors.tax_total}</div>}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="grand_total">Grand Total</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="grand_total"
                                                value={formData.grand_total}
                                                disabled
                                            />
                                        </div>
                                        {formErrors.grand_total && <div className="invalid-feedback">{formErrors.grand_total}</div>}
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-12">
                                        <h2 className="d-flex align-items-center">
                                            <span>Products</span>
                                            {
                                                method != 'view' &&
                                                <a className="text-primary ml-3" href="javascript:void(0);" style={{fontSize: '0.7em'}} onClick={addRow}>
                                                    <i className="fas fa-plus-circle"></i>
                                                </a>
                                            } 
                                        </h2>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="is-required-field">Product</th>
                                                    <th className="is-required-field">Quantity</th>
                                                    <th className="is-required-field">Tax</th>
                                                    <th className="is-required-field">Sub Total</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    formDataProduct.length > 0 && formDataProduct.map((product, productIndex) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <SelectField
                                                                        name="product"
                                                                        value={product.product}
                                                                        options={ddProducts}
                                                                        optLabelField="product"
                                                                        onChangeFn={(e) => {onProductFieldChanged(e, productIndex)}}
                                                                        className={(formErrors.order_details && formErrors.order_details[productIndex] && formErrors.order_details[productIndex].product ? 'is-invalid' : '')}
                                                                        disabled = {method === 'view'} />
                                                                        {
                                                                            formErrors.order_details && formErrors.order_details[productIndex] && formErrors.order_details[productIndex].product &&
                                                                            <div className="invalid-feedback">{formErrors.order_details[productIndex].product}</div>
                                                                        }
                                                                </td>
                                                                <td>
                                                                    <CustomInputField
                                                                        name="product_count"
                                                                        value={product.product_count}
                                                                        fieldType="number"
                                                                        onChangeFn={e => onProductFieldChanged(e, productIndex)}
                                                                        className={
                                                                            (
                                                                                formErrors.order_details && formErrors.order_details[`${productIndex}`] && formErrors.order_details[`${productIndex}`].product_count ? 'is-invalid' : ''
                                                                            )
                                                                        }
                                                                        disabled={method === 'view'} />
                                                                    {
                                                                        formErrors.order_details && formErrors.order_details[productIndex] && formErrors.order_details[productIndex].product_count &&
                                                                        <div className="invalid-feedback">{formErrors.order_details[productIndex].product_count}</div>
                                                                    }
                                                                </td>
                                                                 <td>
                                                                    <SelectField
                                                                        name="tax_id"
                                                                        value={product.tax_id}
                                                                        options={taxesList}
                                                                        onChangeFn={e => onProductFieldChanged(e, productIndex)}
                                                                        className={
                                                                            (formErrors.details && formErrors.details[productIndex] && formErrors.details[productIndex].tax_id ? 'is-invalid' : '')
                                                                        }
                                                                        disabled = {method === 'view'}
                                                                        isClearable={false} />
                                                                        {
                                                                            formErrors.details && formErrors.details[productIndex] && formErrors.details[productIndex].tax_id &&
                                                                            <div className="invalid-feedback">{formErrors.details[productIndex].tax_id}</div>
                                                                        }
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        name="subtotal"
                                                                        className={"form-control " + (formErrors.order_details && formErrors.order_details[productIndex] && formErrors.order_details[productIndex].subtotal ? 'is-invalid' : '')}
                                                                        value={product.subtotal}
                                                                        disabled
                                                                    />
                                                                    {
                                                                        formErrors.order_details && formErrors.order_details[productIndex] && formErrors.order_details[productIndex].subtotal &&
                                                                        <div className="invalid-feedback">{formErrors.order_details[productIndex].subtotal}</div>
                                                                    }
                                                                </td>
                                                                <td>{
                                                                        method != 'view' &&
                                                                        <div>
                                                                            <a className="text-primary mt-5" href="javascript:void(0);" onClick={addRow}>
                                                                                <i className="fas fa-plus-circle" style={{fontSize:'1.1em'}}></i>
                                                                            </a>
                                                                            &nbsp;|&nbsp;
                                                                            <a className="text-danger" href="javascript:void(0);"  onClick={() => deleteRow(productIndex)}>
                                                                                <i className="fas fa-trash " style={{fontSize:'1.1em'}}></i>
                                                                            </a>
                                                                        </div>
                                                                    }
                                                                    
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                                {
                                                    formDataProduct.length == 0 &&
                                                    <>
                                                        <tr>
                                                            <td colSpan="6" className="text-center">
                                                                No prices added for the Product. Click <a href="javascript:void(0);" onClick={addRow}>here</a> to add one
                                                            </td>
                                                        </tr>
                                                    </>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col d-flex justify-content-end">
                                        <button type="button" className="btn btn-default" onClick={handleNavigateProductList}>
                                            <i className="fas fa-angles-left"></i>&nbsp;Go Back
                                        </button>
                                    </div>
                                    <div className="col">

                                    {
                                        method != 'view' &&
                                            <button type="submit" className="btn btn-primary">
                                                {formData.id ? 'Update' : 'Save'}
                                            </button>
                                     }
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddSaleOrder;
