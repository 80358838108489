import {
    useEffect,
    useState
} from 'react';

import Constants from '../../Constants';
import { PagePreLoader } from '../../widgets/PreLoader';

import { setWindowTitle, togglePreloader } from "../../helpers/viewHelper";
import { Outlet } from 'react-router-dom';

function UnAuthenticatedPageLayout() {
    const [ pageTitle, setPageTitle ] = useState('');

    useEffect(() => {
        document.body.classList.add('login-page');

        document.body.classList.remove('hide-on-print');

        togglePreloader('hide');
    }, []);

    useEffect(() => {
        setWindowTitle(pageTitle);
    }, [pageTitle]);

    return (
        <div className="login-box">
            <PagePreLoader />

            <div className="login-logo">
                <b>{Constants.APP_NAME}</b>
            </div>

            <div className="card">
                <div className="card-body login-card-body">
                    <Outlet context={{
                        setPageTitle,
                    }} />
                </div>
            </div>
        </div>
    );
}

export default UnAuthenticatedPageLayout;
