import { useEffect } from "react";

import ErrorPageLayout from "./wrapper/ErrorPageLayout";

function SplashScreen() {
    useEffect(() => {
    }, []);

    return (
        <ErrorPageLayout>
            <p>Loading...</p>
        </ErrorPageLayout>
    );
}

export default SplashScreen;
