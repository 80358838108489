import React, { useEffect, useRef } from "react";
import { RequestBackendAPI } from "../../../helpers/http";

import { Link, useOutletContext ,useParams } from "react-router-dom";
import { toast } from "../../../helpers/viewHelper";
import Table from "../../../widgets/Table";
import { confirmDialog , togglePreloader } from "../../../helpers/viewHelper";
import Tooltip from "../../../widgets/Tooltip";

function PurchaseOrderList() {
    const { setPageTitle } = useOutletContext();
    const columns = [
        {
            'column': 'name',
            'label': 'Order #',
        },
        {
            'column': 'location',
            'label': 'Location',
        },
        {
            'column': 'vendor',
            'label': 'Vendor',
        },
        {
            'column': 'grand_total',
            'label': 'Grand Total',
        },
        {
            'column': 'payment_mode',
            'label': 'Payment Mode',
        },
        {
            'column': 'status',
            'label': 'Status',
        },
    ];

    const tableRef = useRef(null);

    useEffect(() => {
        setPageTitle('Purchase Orders');
    }, []);
    

    const handlePurchaseOrdersDelete = (order) => {
        console.log("order",order)
        let id = order.id;
        console.log("id",id)
        RequestBackendAPI({
            path: 'purchaseOrder.delete',
            pathReplaces: {
                'id': id,
            },
            method:'POST',
            callback:(success,response) => {
                if(success){

                    if (tableRef.current) {
                        tableRef.current.refreshTable();
                    }

                    toast('success', response.message ?? 'Purchase Order Delete successfully.');
                }else{
                    // toast('error', response.message ?? 'Unable to Purchase Order Delete');
                    toast('error', response.data.message ?? 'Unable to Purchase Order Delete');
                }
            }
        })
    }

    const PurchaseOrderDelateConfirmation = (e, order) => {
        e.preventDefault();

        e.target.blur();

        confirmDialog({
            message:'Are you sure you want to delete this Purchase Order',
            confirmBtn:{
                onClick:() => {
                    handlePurchaseOrdersDelete(order)
                }
            },
            cancelBtn:{
                onClick:() => {

                }
            }
        })
    }


    const handlePurchaseOrderComplete = (order) => {
        let id = order.id;
        RequestBackendAPI({
            path: 'purchaseOrder.status.complete',
            pathReplaces: {
                'id': id,
            },
            method:'POST',
            callback:(success,response) => {
                console.log(response);
                if(success){

                    if (tableRef.current) {
                        tableRef.current.refreshTable();
                    }

                    toast('success', response.message ?? 'Purchase Order Completed successfully.');
                }else{
                    if(response){
                        toast('error', response.message ?? 'Unable to Purchase Order Complete');
                    }else{
                        toast('error', response ?? 'Unable to Purchase Order Complete');
                    }
                }
            }
        })
    }

    const purchaseOrderCompleteConfirmation = (e, order) => {
        e.preventDefault();

        e.target.blur();
        console.log(order)
        confirmDialog({
            message:'Are you sure you want to complete this Purchase Order',
            confirmBtn:{
                onClick:() => {
                    handlePurchaseOrderComplete(order)
                }
            },
            cancelBtn:{
                onClick:() => {

                }
            }
        })
    }

    const cancelPurchaseOrder = (order) => {
        try {
            togglePreloader('show');

            RequestBackendAPI({
                path: 'purchaseOrder.status.cancel',
                pathReplaces: {
                    'id': order.id,
                },
                method:'POST',
                callback:(success, response) => {
                    let message = response && response.message ? response.message : '';

                    if (!success) {
                        if (!message) {
                            message = 'The Purchase Order has been cancelled.';
                        }

                        if (tableRef.current) {
                            tableRef.current.refreshTable();
                        }
                    } else {
                        if (!message) {
                            message = 'Unable to cancel the Purchase Order.';
                        }
                    }

                    // toast((success ? 'success' : 'error'), message);
                    toast('success',message ?? 'The Purchase Order has been cancelled');

                    togglePreloader('hide');
                }
            });
        } catch(err) {
            console.log('[cancelOrder] Error occurred:');

            toast('error', 'Some error occurred when trying to cancel the Purchase Order.');

            togglePreloader('hide');
        }
    };

    const onCancelPurchaseOrderClicked = (e, order) => {
        e.preventDefault();

        document.activeElement.blur();

        confirmDialog({
            message: 'Are you sure you want to cancel this Purchase Order?',
            confirmBtn: {
                onClick: () => {
                    cancelPurchaseOrder(order);
                },
            },
            cancelBtn: {
                onClick: () => {},
            },
        });
    };


return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4 mb-3">
                                    <Link to={'/orders/purchase-orders/add'} className="btn btn-primary">
                                        <i className="fas fa-plus-circle"></i> Add Purchase Orders
                                    </Link>
                                </div>
                            </div>

                            <Table
                                apiPath='purchaseOrder.list'
                                reqMethod='POST'
                                ref={tableRef}
                                dataKeyFromResponse='orders'
                                columns={columns}
                                actionColumnFn={(rowData) => {
                                    return (
                                        <>
                                            {/* <Link className="text-primary"  href="javascript:void(0);" to={`/orders/purchase-orders/edit/${rowData.id}`}>
                                                <i className="fas fa-pencil"></i>
                                            </Link>
                                            &nbsp;|&nbsp;
                                            <a className="text-danger" href="javascript:void(0);" onClick={(e) => PurchaseOrdersDelateConfirmation(e, rowData)}>
                                                <i className="fas fa-trash"></i>
                                            </a> */}
                                            {
                                                rowData.status == "draft" && 
                                                <>
                                                    <Tooltip type="route" className="text-primary" to={`/orders/purchase-orders/edit/${rowData.id}`} title="Edit">
                                                        <i className="fas fa-pencil"></i>
                                                    </Tooltip>
                                                    &nbsp;|&nbsp;
                                                    <Tooltip type="btn" className="text-success" href="javascript:void(0);" onClick={(e) => purchaseOrderCompleteConfirmation(e, rowData)} title="Confirm">
                                                        <i className="fas fa-check-double"></i>
                                                    </Tooltip>
                                                    &nbsp;|&nbsp;
                                                    <Tooltip type="btn" className="text-warning" href="javascript:void(0);" onClick={(e) => onCancelPurchaseOrderClicked(e, rowData)} title="Cancel">
                                                        <i className="fas fa-ban"></i>
                                                    </Tooltip>
                                                    &nbsp;|&nbsp;
                                                    <Tooltip type="btn" className="text-danger" href="javascript:void(0);" onClick={(e) => PurchaseOrderDelateConfirmation(e, rowData)} title="Delete">
                                                        <i className="fas fa-trash"></i>
                                                    </Tooltip>
                                                </>
                                            }
                                            {
                                                (rowData.status == "completed" || rowData.status == "canceled") &&
                                                <>
                                                    <Tooltip type="route" className="text-primary" to={`/orders/purchase-orders/edit/${rowData.id}`} title="View">
                                                        <i className="fas fa-eye"></i>
                                                    </Tooltip>

                                                </>
                                            }
                                        </>
                                    );
                                }} />

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PurchaseOrderList;
