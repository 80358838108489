import {
    useEffect
} from 'react';

import Constants from '../../Constants';
import { PagePreLoader } from '../../widgets/PreLoader';

import { togglePreloader } from "../../helpers/viewHelper";

function ErrorPageLayout({children, title = ''}) {
    useEffect(() => {
        document.body.classList.add('login-page');

        document.body.classList.remove('hide-on-print');

        let tabTitle = Constants.APP_NAME;

        if (title) {
            tabTitle = title + ' | ' + tabTitle;
        }

        document.title = tabTitle;

        togglePreloader('hide');
    }, []);

    return (
        <div className="">
            <PagePreLoader />

            {children}
        </div>
    );
}

export default ErrorPageLayout;
