import $ from 'jquery';
import Constants from '../Constants';

export function togglePreloader(action) {
    if (action == 'show') {
        $('div[id="pagePreloader"]').css('height', '100vh');

        setTimeout(() => {
            $('div[id="pagePreloader"]').children().show();
        }, 200);
    } else {
        $('div[id="pagePreloader"]').css('height', 0);

        setTimeout(() => {
            $('div[id="pagePreloader"]').children().hide();
        }, 200);
    }
}

export function toast(type, message) {
    if (typeof(window.toastr) != 'undefined') {
        if (type == 'success') {
            window.toastr.success(message);
        } else if (type == 'error') {
            window.toastr.error(message);
        } else if (type == 'warning') {
            window.toastr.warning(message);
        } else {
            window.toastr.info(message);
        }
    } else {
        window.alert(message);
    }
}

export function confirmDialog({title = 'Are you sure?', message, confirmBtn = {label: 'Yes', onClick: null}, cancelBtn = {label: 'No', onClick: null}}) {
    if (typeof(window.Swal) != 'undefined') {
        window.Swal.fire({
            title: title ?? 'Are you sure?',
            text: message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: confirmBtn.label ?? 'Yes',
            cancelButtonText: cancelBtn.label ?? 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                if (typeof(confirmBtn.onClick) == 'function') {
                    confirmBtn.onClick();
                }
            } else {
                if (typeof(cancelBtn.onClick) == 'function') {
                    cancelBtn.onClick();
                }
            }
        });
    } else {
        if (window.confirm(message)) {
            if (typeof(confirmBtn.onClick) == 'function') {
                confirmBtn.onClick();
            }
        } else {
            if (typeof(cancelBtn.onClick) == 'function') {
                cancelBtn.onClick();
            }
        }
    }
}

export function DownloadDataAsFile(data, fileName, mimeType = false) {
    if (data instanceof Blob) {
        //
    } else {
        data = new Blob([ data ], {type: mimeType});
    }

    const dataURL = URL.createObjectURL(data);

    const tempLinkElem = document.createElement('a');
    tempLinkElem.href = dataURL;
    tempLinkElem.setAttribute('download', fileName);
    tempLinkElem.classList.add('d-none');

    document.body.appendChild(tempLinkElem);

    tempLinkElem.click();

    document.body.removeChild(tempLinkElem);

    URL.revokeObjectURL(dataURL);
}

export function PreviewCachedFile(file) {
    if (file) {
        const dataURL = URL.createObjectURL(file);

        const tempLinkElem = document.createElement('a');
            tempLinkElem.href = dataURL;
            tempLinkElem.classList.add('d-none');
            tempLinkElem.setAttribute('target', '_blank');

        document.body.appendChild(tempLinkElem);

        tempLinkElem.click();

        document.body.removeChild(tempLinkElem);

        URL.revokeObjectURL(dataURL);
    }
}

export function setWindowTitle(title) {
    let windowTitle = Constants.APP_NAME;

    if (title) {
        windowTitle = title + ' | ' + windowTitle;
    }

    document.title = windowTitle;
}

export function omitObjKey(obj, key) {
    let resultObj = {...obj};

    if (key) {
        if (!Array.isArray(key)) {
            key = [key];
        }

        for (let i = 0; i < key.length; i++) {
            if (resultObj[key[i]]) {
                delete resultObj[key[i]];
            }
        }
    }

    return resultObj;
}
