import React, { useEffect, useRef, useState } from "react";
import { RequestBackendAPI } from "../../../helpers/http";

import { Link, useNavigate, useOutletContext ,useParams } from "react-router-dom";
import { toast } from "../../../helpers/viewHelper";
import Table from "../../../widgets/Table";
import { confirmDialog , togglePreloader } from "../../../helpers/viewHelper";
import Modal from "../../../widgets/Modal";
import Tooltip from "../../../widgets/Tooltip";
import { StockTransferLink, StockTransferScreen } from "./ProductStockTransfer";

function ProductList() {
    const navigate = useNavigate();
    const { setPageTitle } = useOutletContext();
    const columns = [
        {
            'column': 'code',
            'label': 'Code',
        },
        {
            'column': 'name',
            'label': 'Product',
        },
        {
            'column': 'detailed_type',
            'label': 'Product Type',
        },
        {
            'column': 'material_status',
            'label': 'Material Status',
        },
        {
            'column': 'veg_or_non_veg',
            'label': 'Veg or Non-Veg',
        },
        {
            'column': 'expiration_days',
            'label': 'Expiration Days',
        },
    ];

    const tableRef = useRef(null);

    const [ isStockTfrScreenVisible, toggleStockTfrScreen ] = useState(false);
    const [ stockTfrProduct, setStockTfrProduct ] = useState(false);

    useEffect(() => {
        setPageTitle('Products');
    }, []);
    

    const handleProductDelete = (product) => {
        let id = product.id;

        console.log("product ID" , id)
        togglePreloader('show');
        RequestBackendAPI({
            path: `productMaster.delete`,
            pathReplaces: {
                'id': id,
            },
            method:'POST',
            callback:(success,response) => {
                console.log(response)
                if(success){
                    console.log("Delete Success")

                    if (tableRef.current) {
                        tableRef.current.refreshTable();
                    }

                    toast('success', response.message ?? 'product Delete successfully.');
                    togglePreloader('hide');
                }else{
                    toast('error', response.message ?? 'Unable to product Delete');
                    console.log("response Delete",response);
                    togglePreloader('hide');
                }
            }
        })
    }

    const productDelateConfirmation = (e, product) => {
        e.preventDefault();

        e.target.blur();

        confirmDialog({
            message:'Are you sure you want to delete this product',
            confirmBtn:{
                onClick:() => {
                    handleProductDelete(product)
                }
            },
            cancelBtn:{
                onClick:() => {

                }
            }
        })
    }

    const redirectToStockUpdatesScreen = (e, product) => {
        e.preventDefault();

        navigate(`/products/${product.id}/stock-updates`, {
            state: {
                'productName': product.name,
            },
        });
    };

    const showTransferStockPopup = (e, product) => {
        e.preventDefault();

        document.activeElement.blur();

        setStockTfrProduct(product);

        toggleStockTfrScreen(true);
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4 mb-3">
                                    <Link to={'/admin/products/add'} className="btn btn-primary">
                                        <i className="fas fa-plus-circle"></i> Add product
                                    </Link>
                                </div>
                            </div>

                            <Table
                                apiPath='productMaster.list'
                                reqMethod='POST'
                                ref={tableRef}
                                dataKeyFromResponse='products'
                                columns={columns}
                                actionColumnFn={(rowData) => {
                                    return (
                                        <>
                                            <Tooltip type="route" className="text-primary" to={`/admin/products/edit/${rowData.id}`} title="Edit Product">
                                                <i className="fas fa-pencil"></i>
                                            </Tooltip>
                                            &nbsp;|&nbsp;
                                            <Tooltip type="route" className="text-info" to={`/products/${rowData.id}/stock-updates`} onClick={e => redirectToStockUpdatesScreen(e, rowData)} title="Stock Update History">
                                                <i className="fas fa-history"></i>
                                            </Tooltip>
                                            &nbsp;|&nbsp;
                                            <StockTransferLink
                                                onClick={e => showTransferStockPopup(e, rowData)} />
                                            &nbsp;|&nbsp;
                                            <Tooltip type="btn" className="text-danger" onClick={(e) => productDelateConfirmation(e, rowData)} title="Delete Product">
                                                <i className="fas fa-trash"></i>
                                            </Tooltip>
                                        </>
                                    );
                                }} />

                            <StockTransferScreen
                                productId={stockTfrProduct && stockTfrProduct.id ? stockTfrProduct.id : null}
                                isTfrScreenVisible={isStockTfrScreenVisible}
                                toggleTfrScreen={toggleStockTfrScreen} />

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProductList;
