import React, { useEffect, useState } from "react";
import { useOutletContext, useParams ,useNavigate, Link} from "react-router-dom";
import { objectToFormData, RequestBackendAPI } from "../../../helpers/http";
import { DownloadDataAsFile, PreviewCachedFile, toast, togglePreloader } from "../../../helpers/viewHelper";
import SelectField from "../../../widgets/Fields/SelectField";
import CustomInputField from "../../../widgets/Fields/CustomInputField";

function AddPurchaseOrder() {
    const { user, setPageTitle } = useOutletContext();
    const navigate = useNavigate()
    const { id ,method} = useParams();
    const [ isViewMode, setViewMode ] = useState(false);

    useEffect(() => {
        setPageTitle((id && method ? 'View':id ? 'Edit' :'Add') + ' Purchase Orders');

        if(id) {
            getPurchaseOrderInfo(id)
        } else {
            masterDropDown();

            if (user && user.suluva_pos_user_role === 'user' && user.location) {
                setFormData({
                    ...formData,
                    'location': user.location.id,
                });

                onLocationChanged(user.location.id);
            }
        }
    }, [id]);

    const initialAttachmentData = {
        id: null,
        file_name: null,
        downloadURL: null,
        file: null,
    };

    const initialFormData = {
        id: '',
        name: '',
        location: '',
        vendor: '',
        subtotal: '',
        tax_total: '',
        grand_total: '',
        payment_mode: '',
        purchase_person: '',
        purchase_datetime: '',
        purchase_notes: '',
    };

    const orderDetailsInitialFormData = [{
        id: '',
        product: '',
        product_count: '',
        unit_price: '',
        tax_id: '',
        tax_amount: '',
        subtotal: '',
    }];

    const [formData, setFormData] = useState({ ...initialFormData });

    const [formDataProductData, setFormDataProductData] = useState([...orderDetailsInitialFormData]);

    const [attachments, setAttachments] = useState([]);
    const [deletedAttachments, setDeletedAttachments] = useState([]);

    const [formErrors, setFormErrors] = useState({});

    const [paymentModes, setPaymentModes] = useState({});
    const [locations, setLocations] = useState({});
    const [vendors, setVendors] = useState({});
    const [productsList, setProductsList] = useState({});
    const [taxesList, setTaxesList] = useState({});

    function handleInputChange(event) {
        const {name, value} = event.target;

        setFormData({
            ...formData,
            [name]: value,
        });

        if (name == 'location') {
            onLocationChanged(value);
        }
    }

    function handleFileAttachmentInputChange(event, index) {
        const { files } = event.target;

        const newAttachments = [...attachments];

        if (files && files.length > 0) {
            newAttachments[index].file = files[0];
        }

        setAttachments([...newAttachments]);
    }
 
    const handleOrderDetailsInputChange = (event, productIndex) => {
        const { name, value } = event.target;

        let newOrderDetailsData = [...formDataProductData];

        newOrderDetailsData[productIndex][name] = value;

        let productId = newOrderDetailsData[productIndex].product;
        let count = newOrderDetailsData[productIndex].product_count;
        let unitPrice = newOrderDetailsData[productIndex].unit_price;

        if (productId && productsList && productId in productsList && productsList[productId]) {
            if (!isNaN(Number(count)) && !isNaN(Number(unitPrice))) {
                newOrderDetailsData[productIndex].subtotal = Number(count) * Number(unitPrice);
            }
        }

        let orderSubTotal = 0,
            orderTaxTotal = 0;

        for (let i = 0; i < newOrderDetailsData.length; i++) {
            let itemSubTotal = 0, itemTaxTotal = 0;

            if (newOrderDetailsData[i].subtotal && !isNaN(Number(newOrderDetailsData[i].subtotal))) {
                itemSubTotal = Number(newOrderDetailsData[i].subtotal);
            }

            let taxId = newOrderDetailsData[i].tax_id;

            if (taxId && taxesList && taxId in taxesList && taxesList[taxId]) {
                if (taxesList[taxId].percent) {
                    itemTaxTotal = itemSubTotal * taxesList[taxId].percent/100;
                }
            }

            orderSubTotal += itemSubTotal;
            orderTaxTotal += itemTaxTotal;
        }

        setFormDataProductData([...newOrderDetailsData]);

        setFormData({
            ...formData,
            subtotal: orderSubTotal,
            tax_total: orderTaxTotal,
            grand_total: (orderSubTotal + orderTaxTotal),
        });
    }

    const onDownloadFileAttachmentClicked = (e, attachment) => {
        e.preventDefault();

        try {
            togglePreloader('show');

            document.activeElement.blur();

            RequestBackendAPI({
                path: attachment.downloadURL,
                method: 'GET',
                data: {
                    time: (new Date()).getTime(),
                },
                jsJSON: false,
                responseType: 'blob',
                callback: (success, response) => {
                    if (success) {
                        if (response instanceof Blob) {
                            DownloadDataAsFile(response, attachment.file_name);
                        } else {
                            toast('error', 'Failed to download the file.');
                        }
                    } else {
                        toast('error', 'Unable to download the file.');
                    }

                    togglePreloader('hide');
                },
            });
        } catch(err) {
            togglePreloader('hide');

            console.log('[onDownloadFileAttachmentClicked] Error:', err);

            toast('error', 'Some error has occurred.');
        }
    };

    const onOpenCachedFileClicked = (e, attachment) => {
        e.preventDefault();

        try {
            togglePreloader('show');

            document.activeElement.blur();

            PreviewCachedFile(attachment.file);

            togglePreloader('hide');
        } catch(err) {
            togglePreloader('hide');

            console.log('[onOpenCachedFileClicked] Error:', err);

            toast('error', 'Some error has occurred.');
        }
    };

    const handleNavigatePurchaseList = () => {
        navigate("/orders/purchase-orders");
    };

    const renderDropDownsFromResponseData = (responseData, dataNeeded = 'initial') => {
        let newPaymentMode = {};
        let newLocation = {};
        let newVendors = {};
        let newTaxes = {};
        let newProducts = {};

        if (responseData && responseData.dropDowns) {
            const dropDowns = responseData.dropDowns;

            if (dropDowns.paymentModes && typeof(dropDowns.paymentModes) == 'object' && !(dropDowns.paymentModes instanceof Array)) {
                newPaymentMode = {...dropDowns.paymentModes};
            } 

            if (dropDowns.locations && typeof(dropDowns.locations) == 'object' && !(dropDowns.locations instanceof Array)) {
                newLocation = {...dropDowns.locations};
            }

            if (dropDowns.vendors && typeof(dropDowns.vendors) == 'object' && !(dropDowns.vendors instanceof Array)) {
                newVendors = {...dropDowns.vendors};
            }

            if (dropDowns.taxes && typeof(dropDowns.taxes) == 'object' && !(dropDowns.taxes instanceof Array)) {
                newTaxes = {...dropDowns.taxes};
            }

            if (dropDowns.products && typeof(dropDowns.products) == 'object' && !(dropDowns.products instanceof Array)) {
                newProducts = {...dropDowns.products};
            }
        }

        if (dataNeeded == 'all' || dataNeeded == 'initial') {
            setPaymentModes({...newPaymentMode});
            setLocations({...newLocation});
            setVendors({...newVendors});
            setTaxesList({...newTaxes});
        }

        if (dataNeeded == 'all' || dataNeeded == 'products') {
            setProductsList({...newProducts});
        }
    };

    const masterDropDown = () => {
        RequestBackendAPI({
            path: `purchaseOrder.dropDowns`,
            method: 'POST',
            callback: (success, response) => {
                renderDropDownsFromResponseData(success && response && response.data ? response.data : {}, 'initial');
            }
        });
    }


    const onLocationChanged = (location) => {
        RequestBackendAPI({
            path: `purchaseOrder.dropDowns`,
            method: 'POST',
            data: {
                'dataNeeded': 'products',
                'filters': {
                    'location': location,
                },
            },
            callback: (success, response) => {
                renderDropDownsFromResponseData(success && response && response.data ? response.data : {}, 'products');
            }
        });
    }

    const handleAddProduct = (e) => {
        e.preventDefault();

        if (!isViewMode) {
            submitProductForm();
        }
    };

    const submitProductForm = () => {
        const formattedDatetime = formData.purchase_datetime ? formData.purchase_datetime.replace('T', ' ') : '';

        let jsonFormData = {
            ...formData,
            'purchase_datetime': formattedDatetime,
        };

        let attachmentData = [];
        let attachmentFiles = [];

        if (attachments) {
            for (let i = 0; i < attachments.length; i++) {
                if (attachments[i].file) {
                    attachmentData.push({
                        'id': attachments[i].id,
                        'file_name': attachments[i].file_name,
                    });
                    attachmentFiles.push(attachments[i].file);
                }
            }
        }

        let requestFormData = objectToFormData({
            ...jsonFormData,
            'details': JSON.stringify([...formDataProductData]),
            'attachmentData': JSON.stringify(attachmentData),
            'attachmentFiles': attachmentFiles,
            'deletedAttachments': JSON.stringify(deletedAttachments),
        })

        togglePreloader('show');

        RequestBackendAPI({
            path: 'purchaseOrder.save',
            method: 'POST',
            data: requestFormData,
            isJSON: false,
            callback: (success, response) => {
                // console.log('response:', response);
                let message = response ? response.message : null;
                if (success) {
                    toast('success', message ?? 'Purchase order created successfully..');
                    handleNavigatePurchaseList()
                    togglePreloader('hide');
                } else {
                    setFormErrors(response ? (response.errors ?? {}) : {});
                    toast('error', message ?? 'Unable to add the purchase order.');
                    togglePreloader('hide');
                }
            }
        });
    };

    // HandleEditUser
    const getPurchaseOrderInfo = (orderId) => {
        RequestBackendAPI({
            path: `purchaseOrder.edit`,
            pathReplaces: {
                'id': orderId
            },
            method: 'POST',
            callback: (success, response) => {
                if (success  && response && response.data) {
                    let newOrderData = {...initialFormData};
                    let newOrderDetailsData = [];
                    let newAttachments = [];

                    renderDropDownsFromResponseData(response.data, 'all');

                    let orderData = {
                        ...initialFormData,
                        ...response.data.PurchaseOrder
                    };

                    if (response.data.order_details && Array.isArray(response.data.order_details)) {
                        for (let i = 0; i < response.data.order_details.length; i++) {
                            newOrderDetailsData.push({
                                ...orderDetailsInitialFormData,
                                ...response.data.order_details[i],
                            });
                        }
                    }

                    if (response.data.attachments) {
                        newAttachments = [...response.data.attachments];
                    }

                    // formData
                    setFormData({
                        ...orderData,
                    });

                    // orderDetailsInitialFormData
                    setFormDataProductData([...(newOrderDetailsData ?? [])]);

                    setAttachments([...(newAttachments ?? [])]);

                    onLocationChanged(newOrderData.location);

                    setViewMode(orderData.order_status != 'draft');
                } else {
                    // toast('error', response.data.message ?? 'Unable to fetch purchase order data');
                }
            }
        });
    }
    // HandleEditUser


    // Multiple file Upload 
    const addFileRow = () => {
        setAttachments([...attachments, {...initialAttachmentData}]);
    };

    const deleteFileRow = (index) => {
        setDeletedAttachments([...deletedAttachments, attachments[index].id]);
        setAttachments([...attachments.filter((_, i) => i !== index)]);
    };

    const handleFileConfirm = () => {
        document.querySelector('#fileAttachmentsModal .close').click();
    };

    // Multiple file Upload 

    // Product
     const addProductRow = () => {
        setFormDataProductData([
            ...formDataProductData,
            {...orderDetailsInitialFormData},
        ]);
    };

    const deleteProductRow = (index) => {
        setFormDataProductData(formDataProductData.filter((_, i) => i !== index));
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card p-2">
                        <div className="card-header">
                            <h5>Purchase Order</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={handleAddProduct} disabled={isViewMode}>
                                {
                                    formData.id &&
                                    <div class="row">
                                        <div className="col-lg-6 col-12">
                                            <div className="form-group mb-3">
                                                <label htmlFor="name">Purchase #</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    className="form-control"
                                                    value={formData.name}
                                                    onChange={handleInputChange}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div class="row">
                                    <div className="col">
                                        <div className="form-group is-required-field">
                                            <label htmlFor="location">Location</label>
                                            <SelectField
                                                name="location"
                                                value={formData.location}
                                                options={locations}
                                                onChangeFn={handleInputChange}
                                                className={(formErrors.location ? 'is-invalid' : '')}
                                                disabled={(user && user.suluva_pos_user_role === 'user' && user.location) || isViewMode} />
                                        </div>
                                        {formErrors.location && <div className="invalid-feedback">{formErrors.location}</div>}
                                    </div>

                                    <div className="col">
                                        <div className="form-group is-required-field">
                                            <label htmlFor="vendor">Vendor</label>
                                            <SelectField
                                                name="vendor"
                                                value={formData.vendor}
                                                options={vendors}
                                                onChangeFn={handleInputChange}
                                                className={(formErrors.vendor ? 'is-invalid' : '')}
                                                disabled={isViewMode} />
                                        </div>
                                        {formErrors.vendor && <div className="invalid-feedback">{formErrors.vendor}</div>}
                                    </div>
                                </div>

                                <div class="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group is-required-field">
                                            <label htmlFor="payment_mode">Payment Mode</label>
                                            <SelectField
                                                name="payment_mode"
                                                value={formData.payment_mode}
                                                options={paymentModes}
                                                onChangeFn={handleInputChange}
                                                className={(formErrors.payment_mode ? 'is-invalid' : '')}
                                                disabled={isViewMode} />
                                        </div>
                                        {formErrors.type && <div className="invalid-feedback">{formErrors.type}</div>}
                                    </div>
                                </div>

                                <div class="row">
                                    <div className="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="purchase_person">Purchase Person</label>
                                            <input
                                                type="text"
                                                name="purchase_person"
                                                className={`form-control ${formErrors.purchase_person ? 'is-invalid' : ''}`}
                                                value={formData.purchase_person}
                                                onChange={handleInputChange}
                                                disabled={isViewMode}
                                            />
                                            {formErrors.purchase_person && <div className="invalid-feedback">{formErrors.purchase_person}</div>}
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="purchase_datetime">Purchase DateTime</label>
                                            <input
                                                type="datetime-local"
                                                name="purchase_datetime"
                                                className={`form-control ${formErrors.purchase_datetime ? 'is-invalid' : ''} `}
                                                value={formData.purchase_datetime}
                                                onChange={handleInputChange}
                                                disabled={isViewMode}
                                            />
                                            {formErrors.purchase_datetime && <div className="invalid-feedback">{formErrors.purchase_datetime}</div>}
                                        </div>                
                                    </div>
                                </div>

                                <div class="row">
                                    <div className="col">
                                        <div className="form-group mb-3">
                                            <label htmlFor="purchase_notes">Purchase Notes</label>
                                            <textarea
                                                type="text"
                                                name="purchase_notes"
                                                rows="3"
                                                placeholder="Enter ..."
                                                className={`form-control ${formErrors.purchase_notes ? 'is-invalid' : ''}`}
                                                value={formData.purchase_notes}
                                                onChange={handleInputChange}
                                                disabled={isViewMode}
                                            />
                                            {formErrors.purchase_notes && <div className="invalid-feedback">{formErrors.purchase_notes}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div className="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="subtotal">Subtotal</label>
                                            <input
                                                type="text"
                                                name="subtotal"
                                                className={`form-control ${formErrors.subtotal ? 'is-invalid' : ''}`}
                                                value={formData.subtotal}
                                                onChange={handleInputChange}
                                                readOnly
                                            />
                                            {formErrors.subtotal && <div className="invalid-feedback">{formErrors.subtotal}</div>}
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="tax_total">Tax Total</label>
                                            <input
                                                type="text"
                                                name="tax_total"
                                                className={`form-control ${formErrors.tax_total ? 'is-invalid' : ''}`}
                                                value={formData.tax_total}
                                                onChange={handleInputChange}
                                                readOnly
                                            />
                                            {formErrors.tax_total && <div className="invalid-feedback">{formErrors.tax_total}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="grand_total">Grand Total</label>
                                            <input
                                                type="text"
                                                name="grand_total"
                                                className={`form-control ${formErrors.grand_total ? 'is-invalid' : ''}`}
                                                value={formData.grand_total}
                                                onChange={handleInputChange}
                                                readOnly
                                            />
                                            {formErrors.grand_total && <div className="invalid-feedback">{formErrors.grand_total}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-lg-6 col-12">
                                        <div className={"form-group"}>
                                            <label>Attachments</label>
                                            <a href="javascript:void(0);" className={"form-control " + (formErrors.attachmentFiles ? 'is-invalid' : '')} data-toggle="modal" data-target="#fileAttachmentsModal">
                                                {attachments.length > 0 ? `${attachments.length} file(s) attached` : 'Click here to choose files'}
                                            </a>
                                            {
                                                formErrors.attachmentFiles && typeof(formErrors.attachmentFiles) == 'string' &&
                                                <div className="invalid-feedback">{formErrors.attachmentFiles}</div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div class="modal fade" id="fileAttachmentsModal" tabindex="-1" role="dialog" aria-labelledby="fileAttachmentsModalTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                                    <div class="modal-dialog modal-dialog-scrollable" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="fileAttachmentsModalTitle">File Attachments</h5>
                                                    {
                                                        !isViewMode &&
                                                        <a className="text-primary ml-3" href="javascript:void(0);" style={{fontSize: '1.3em'}} onClick={addFileRow}>
                                                            <i className="fas fa-plus-circle"></i>
                                                        </a>
                                                    }
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>File</th>
                                                                    {
                                                                        !isViewMode &&
                                                                        <th>Action</th>
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    attachments.length > 0 && attachments.map((attachment, index) => (
                                                                        <tr>
                                                                            <td>
                                                                                {
                                                                                    !isViewMode &&
                                                                                    <div>
                                                                                        <input
                                                                                            type="file"
                                                                                            name="attachments"
                                                                                            className={
                                                                                                "form-control " +
                                                                                                (
                                                                                                    formErrors.attachmentFiles &&
                                                                                                    typeof(formErrors.attachmentFiles[index]) != 'undefined'
                                                                                                    ? 'is-invalid' : ''
                                                                                                )
                                                                                            }
                                                                                            onChange={(e) => handleFileAttachmentInputChange(e, index)}
                                                                                        />
                                                                                        {
                                                                                            formErrors.attachmentFiles && typeof(formErrors.attachmentFiles[index]) != 'undefined' &&
                                                                                            <div className="invalid-feedback">{formErrors.attachmentFiles[index]}</div>
                                                                                        }
                                                                                    </div>
                                                                                }

                                                                                {
                                                                                    !attachment.file && attachment.downloadURL &&
                                                                                    <div>
                                                                                        <a href="javascript:void(0);" onClick={e => onDownloadFileAttachmentClicked(e, attachment)}>
                                                                                            <i className="fas fa-download"></i>&nbsp;{attachment.file_name}
                                                                                        </a>
                                                                                    </div>
                                                                                }

                                                                                {
                                                                                    attachment.file &&
                                                                                    <div>
                                                                                        <a href="javascript:void(0);" onClick={e => onOpenCachedFileClicked(e, attachment)}>
                                                                                            <i className="fas fa-magnifying-glass-plus"></i>&nbsp;{attachment.file.name}
                                                                                        </a>
                                                                                    </div>
                                                                                }
                                                                            </td>
                                                                            {
                                                                                !isViewMode &&
                                                                                <td className="td-action">
                                                                                    <div>
                                                                                        <a className="text-primary mt-5" href="javascript:void(0);" onClick={addFileRow}>
                                                                                            <i className="fas fa-plus-circle" style={{fontSize:'1.1em'}}></i>
                                                                                        </a>
                                                                                        &nbsp;|&nbsp;
                                                                                        <a className="text-danger" href="javascript:void(0);"  onClick={() => deleteFileRow(index)}>
                                                                                            <i className="fas fa-trash " style={{fontSize:'1.1em'}}></i>
                                                                                        </a>
                                                                                    </div>
                                                                                </td>
                                                                            }
                                                                        </tr>
                                                                    ))
                                                                }
                                                                {
                                                                    attachments.length == 0 &&
                                                                    <>
                                                                        <tr>
                                                                            <td colSpan="6" className="text-center">
                                                                                No File added for the Files. Click <a href="javascript:void(0);" onClick={addFileRow}>here</a> to add one
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div> 
                                            </div>
                                            <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Go Back</button>
                                                    {
                                                        !isViewMode &&
                                                        <button type="button" class="btn btn-primary" data-dismiss="modal" onClick={handleFileConfirm}>Confirm</button>
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-12">
                                        <h2 className="d-flex align-items-center">
                                            <span>Products</span>
                                            {
                                                !isViewMode &&
                                                <a className="text-primary ml-3" href="javascript:void(0);" style={{fontSize: '0.7em'}} onClick={addProductRow}>
                                                    <i className="fas fa-plus-circle"></i>
                                                </a>
                                            }
                                        </h2>
                                    </div>
                                </div>

                                {/* Order and Product Details */}
                                <div className="row">
                                    <div className="col-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="is-required-field">Product</th>
                                                    <th className="is-required-field">Count</th>
                                                    <th className="is-required-field">Unit Price</th>
                                                    <th className="is-required-field">Tax</th>
                                                    <th className="is-required-field">Sub Total</th>
                                                    {
                                                        !isViewMode &&
                                                        <th>Action</th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    formDataProductData.length > 0 && formDataProductData.map((product, productIndex) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <SelectField
                                                                        name="product"
                                                                        value={product.product}
                                                                        options={productsList}
                                                                        optLabelField="product"
                                                                        onChangeFn={e => handleOrderDetailsInputChange(e, productIndex)}
                                                                        className={
                                                                            (formErrors.details && formErrors.details[productIndex] && formErrors.details[productIndex].product ? 'is-invalid' : '')
                                                                        }
                                                                        disabled = {isViewMode} />
                                                                        {
                                                                            formErrors.details && formErrors.details[productIndex] && formErrors.details[productIndex].product &&
                                                                            <div className="invalid-feedback">{formErrors.details[productIndex].product}</div>
                                                                        }
                                                                </td>
                                                                <td>
                                                                    <CustomInputField
                                                                        name="product_count"
                                                                        value={product.product_count}
                                                                        fieldType="number"
                                                                        onChangeFn={e => handleOrderDetailsInputChange(e, productIndex)}
                                                                        className={(formErrors.details && formErrors.details[productIndex] && formErrors.details[productIndex].product_count ? 'is-invalid' : '')}
                                                                        disabled={isViewMode} />
                                                                    {
                                                                        formErrors.details && formErrors.details[productIndex] && formErrors.details[productIndex].product_count &&
                                                                        <div className="invalid-feedback">{formErrors.details[productIndex].product_count}</div>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <CustomInputField
                                                                        name="unit_price"
                                                                        value={product.unit_price}
                                                                        fieldType="amount"
                                                                        onChangeFn={e => handleOrderDetailsInputChange(e, productIndex)}
                                                                        className={(formErrors.details && formErrors.details[productIndex] && formErrors.details[productIndex].unit_price ? 'is-invalid' : '')}
                                                                        disabled={isViewMode} />
                                                                    {
                                                                        formErrors.details && formErrors.details[productIndex] && formErrors.details[productIndex].unit_price &&
                                                                        <div className="invalid-feedback">{formErrors.details[productIndex].unit_price}</div>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <SelectField
                                                                        name="tax_id"
                                                                        value={product.tax_id}
                                                                        options={taxesList}
                                                                        onChangeFn={e => handleOrderDetailsInputChange(e, productIndex)}
                                                                        className={
                                                                            (formErrors.details && formErrors.details[productIndex] && formErrors.details[productIndex].tax_id ? 'is-invalid' : '')
                                                                        }
                                                                        disabled = {isViewMode} />
                                                                        {
                                                                            formErrors.details && formErrors.details[productIndex] && formErrors.details[productIndex].tax_id &&
                                                                            <div className="invalid-feedback">{formErrors.details[productIndex].tax_id}</div>
                                                                        }
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        name="subtotal"
                                                                        className="form-control"
                                                                        value={product.subtotal}
                                                                        readOnly
                                                                    />
                                                                </td>
                                                                {
                                                                    !isViewMode &&
                                                                    <td className="td-action">
                                                                        <div>
                                                                            <a className="text-primary mt-5" href="javascript:void(0);" onClick={addProductRow}>
                                                                                <i className="fas fa-plus-circle" style={{fontSize:'1.1em'}}></i>
                                                                            </a>
                                                                            &nbsp;|&nbsp;
                                                                            <a className="text-danger" href="javascript:void(0);"  onClick={() => deleteProductRow(productIndex)}>
                                                                                <i className="fas fa-trash " style={{fontSize:'1.1em'}}></i>
                                                                            </a>
                                                                        </div>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        );
                                                    })
                                                }
                                                {
                                                    formDataProductData.length == 0 &&
                                                    <>
                                                        <tr>
                                                            <td colSpan="6" className="text-center">
                                                                No prices added for the Product. Click <a href="javascript:void(0);" onClick={addProductRow}>here</a> to add one
                                                            </td>
                                                        </tr>
                                                    </>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col d-flex justify-content-end">
                                        <button type="button" className="btn btn-default" onClick={handleNavigatePurchaseList}>
                                            <i className="fas fa-angles-left"></i>&nbsp;Go Back
                                        </button>
                                    </div>

                                    <div className="col">
                                        {
                                            !isViewMode &&
                                            <button type="submit" className="btn btn-primary">
                                                {formData.id ? 'Update' : 'Save'}
                                            </button>
                                        }
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddPurchaseOrder;


