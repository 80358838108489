const localDBInstance = window.localStorage;

export const LocalDB = {
    get: (key) => {
        return localDBInstance.getItem(key);
    },
    getJSON: (key) => {
        let value = LocalDB.get(key);

        try {
            if (value && value.length > 0) {
                value = JSON.parse(value);
            } else {
                value = null;
            }
        } catch(err) {
            console.error('[LocalDB: getJSON()] Error when parsing JSON data:', err);

            value = null;
        }

        return value;
    },
    getUserInfo: () => {
        //
    },
    store: (key, value) => {
        return localDBInstance.setItem(key, value);
    },
    storeJSON: (key, value) => {
        let rawValue = value;

        try {
            value = JSON.stringify(rawValue);
        } catch(err) {
            console.error('[LocalDB: storeJSON()] Error when converting Object to string:', err);
            value = rawValue;
        }

        LocalDB.store(key, value);
    },
    delete: (key) => {
        localDBInstance.removeItem(key);
    },
}
