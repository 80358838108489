import React, { useEffect, useState } from "react";
import { useOutletContext, useParams ,useNavigate} from "react-router-dom";
import { RequestBackendAPI } from "../../../helpers/http";
import { toast } from "../../../helpers/viewHelper";
import CustomInputField from "../../../widgets/Fields/CustomInputField";
import SelectField from "../../../widgets/Fields/SelectField";

function AddVendor() {
    const { setPageTitle } = useOutletContext();
    const navigate = useNavigate()
    const { id } = useParams();

    useEffect(() => {
        setPageTitle((id ? 'Edit' : 'Add') + ' Vendor');
        if(id){
            getVendorInfo(id)
        } else {
            getvendorDropDowns();
        }
    }, [id]);
    
    const initialFormData = {
        id: '',
        code: '',
        name: '',
        fssai: '',
        gst: '',
        phone: '',
        email: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        pin_code: '',
        state_id: '',
        country_id: '',
    };

    const [formData, setFormData] = useState({ ...initialFormData });
    const [formErrors, setFormErrors] = useState({});
    const [editMode, setEditMode] = useState(false);

    const [ ddStatesList, setDDStatesList ] = useState([]);
    const [ ddFilteredStatesList, setDDFilteredStatesList ] = useState([]);
    const [ ddCountriesList, setDDCountriesList ] = useState([]);

    const getCountryIdOfState = (stateId) => {
        let countryId;

        for (let i = 0; i < ddFilteredStatesList.length; i++) {
            if (ddFilteredStatesList[i].id == stateId) {
                countryId = ddFilteredStatesList[i].country;
                break;
            }
        }

        return countryId;
    };

    const isStateInChosenCountry = (formData) => {
        let countryId = getCountryIdOfState(formData.state_id);

        return countryId && Number(countryId) == Number(formData.country_id);
    };

    function handleInputChange(event) {
        let {name, value} = event.target;

        // if (name == 'state_id' || name == 'country_id') {
        //     value = event;
        //     /* if (event && typeof(event) == 'object' && event.id && event.name) {
        //         value = event.id;
        //     } */
        // } else {
        //     name = event.target.name;
        //     value = event.target.value;
        // }

        let newFormData = {
            ...formData,
            [name]: value
        };

        if (name === 'state_id') {
            if (newFormData.state_id) {
                let countryId = getCountryIdOfState(newFormData.state_id);

                if (countryId && countryId != newFormData.country_id) {
                    newFormData['country_id'] = countryId;
                }
            }
        } else if (name === 'country_id') {
            if (newFormData.country_id) {
                if (!isStateInChosenCountry(newFormData)) {
                    newFormData['state_id'] = '';
                }
            }
        }

        filterDDStatesList(newFormData, ddStatesList);
        setFormData(newFormData);
    }

    const filterDDStatesList = (formData, ddStatesList) => {
        let newDDFilteredStatesList = [];

        for (let i = 0; i < ddStatesList.length; i++) {
            if (!formData.country_id || ddStatesList[i].country == formData.country_id) {
                newDDFilteredStatesList.push({...ddStatesList[i]});
            }
        }

        setDDFilteredStatesList([...newDDFilteredStatesList]);
    };

    const handleNavigateVendorList = () => {
        navigate("/admin/vendors");
    };


    const handleAddVendor = (e) => {
        e.preventDefault();
        submitVendorForm(formData);
    };

    const submitVendorForm = (submitData) => {
        let formData = {
            ...submitData,
            ...JSON.parse(JSON.stringify(submitData)),
            type: '',
        }

        RequestBackendAPI({
            path: 'vendorMaster.save',
            method: 'POST',
            data: formData,
            callback: (success, response) => {
                if (success) {
                    // setFormData({ ...initialFormData });
                    // setDropDownsFromResponse(success && response && response.data ? response.data : {});

                    toast('success', response && response.message ? response.message : 'vendor add successfully.');
                    handleNavigateVendorList()
                    // setFormErrors({});
                    // if (response.data) {
                    //     setEditMode(false);
                    // }
                } else {
                    setFormErrors(response && response.errors ? response.errors : {});
                    toast('error', response && response.message ? response.message : 'Unable to add vendor');
                }
            }
        });
    };

    // getVendorInfo
    const getVendorInfo = (vendorId) => {
        setFormData('');

        RequestBackendAPI({
            path: 'vendorMaster.edit',
            pathReplaces: {
                'id': vendorId
            },
            method: 'POST',
            callback: (success, response) => {
                if (success) {
                    let VendorData = {
                        ...response.data.vendor
                    };

                    setDropDownsFromResponse(success && response && response.data ? response.data : {}, VendorData);
                    setFormData({...VendorData});
                    setEditMode(true);
                }else{
                    toast('error', response.message ?? 'Unable to fetch vendor data');
                }
            }
        });
    }
    // getVendorInfo

    const setDropDownsFromResponse = (responseData, vendorData) => {
        let newDDStatesList = {}, newDDCountriesList = {};

        if (responseData && responseData.dropDowns) {
            if (responseData.dropDowns.states && typeof(responseData.dropDowns.states) === 'object' && Array.isArray(responseData.dropDowns.states)) {
                newDDStatesList = [...responseData.dropDowns.states];
            }

            if (responseData.dropDowns.countries && typeof(responseData.dropDowns.countries) === 'object' && Array.isArray(responseData.dropDowns.countries)) {
                newDDCountriesList = [...responseData.dropDowns.countries];
            }
        }

        setDDStatesList([...newDDStatesList]);
        setDDCountriesList([...newDDCountriesList]);

        filterDDStatesList(vendorData, newDDStatesList);
    };

    const getvendorDropDowns = () => {
        RequestBackendAPI({
            path: 'vendorMaster.dropDowns',
            method: 'POST',
            callback: (success, response) => {
                if (success) {
                    setDropDownsFromResponse(success && response && response.data ? response.data : {}, {});
                } else {
                    toast('error', response && response.message ? response.message : 'Unable to fetch vendor drop-downs');

                    navigate('/admin/vendors');
                }
            }
        });
    };
    
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card p-2">
                        <div className="card-body">
                            <form onSubmit={handleAddVendor}>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="name">Name</label>
                                            <input
                                                type="text"
                                                name="name"
                                                className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                                                value={formData.name}
                                                onChange={handleInputChange}
                                            />
                                            {formErrors.name && <div className="invalid-feedback">{formErrors.name}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="gst_no">GST No</label>
                                            <input
                                                type="text"
                                                name="gst_no"
                                                className={`form-control ${formErrors.gst_no ? 'is-invalid' : ''}`}
                                                value={formData.gst_no}
                                                onChange={handleInputChange}
                                            />
                                            {formErrors.gst_no && <div className="invalid-feedback">{formErrors.gst_no}</div>}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="fssai_no">FSSAI No</label>
                                            <input
                                                type="text"
                                                name="fssai_no"
                                                className={`form-control ${formErrors.fssai_no ? 'is-invalid' : ''}`}
                                                value={formData.fssai_no}
                                                onChange={handleInputChange}
                                            />
                                            {formErrors.fssai_no && <div className="invalid-feedback">{formErrors.fssai_no}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="phone">Phone No</label>
                                            <CustomInputField
                                                name="phone"
                                                value={formData.phone}
                                                fieldType="phone"
                                                onChangeFn={handleInputChange}
                                                className={(formErrors.phone ? 'is-invalid' : '')} />
                                            {formErrors.phone && <div className="invalid-feedback">{formErrors.phone}</div>}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="email">E-Mail</label>
                                            <input
                                                type="text"
                                                name="email"
                                                className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                                                value={formData.email}
                                                onChange={handleInputChange}
                                            />
                                            {formErrors.email && <div className="invalid-feedback">{formErrors.email}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div className="form-group mb-3">
                                            <label htmlFor="address_line_1">Address Line 1</label>
                                            <input
                                                type="text"
                                                name="address_line_1"
                                                className={`form-control ${formErrors.address_line_1 ? 'is-invalid' : ''}`}
                                                value={formData.address_line_1}
                                                onChange={handleInputChange}
                                            />
                                            {formErrors.address_line_1 && <div className="invalid-feedback">{formErrors.address_line_1}</div>}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group mb-3">
                                            <label htmlFor="address_line_2">Address Line 2</label>
                                            <input
                                                type="text"
                                                name="address_line_2"
                                                className={`form-control ${formErrors.address_line_2 ? 'is-invalid' : ''}`}
                                                value={formData.address_line_2}
                                                onChange={handleInputChange}
                                            />
                                            {formErrors.address_line_2 && <div className="invalid-feedback">{formErrors.address_line_2}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div className="form-group mb-3">
                                            <label htmlFor="city">City</label>
                                            <input
                                                type="text"
                                                name="city"
                                                className={`form-control ${formErrors.city ? 'is-invalid' : ''}`}
                                                value={formData.city}
                                                onChange={handleInputChange}
                                            />
                                            {formErrors.city && <div className="invalid-feedback">{formErrors.city}</div>}
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="form-group mb-3">
                                            <label htmlFor="pin_code">PIN Code</label>
                                            <CustomInputField
                                                name="pin_code"
                                                value={formData.pin_code}
                                                fieldType="zip"
                                                onChangeFn={handleInputChange}
                                                className={(formErrors.pin_code ? 'is-invalid' : '')} />
                                            {formErrors.pin_code && <div className="invalid-feedback">{formErrors.pin_code}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="state_id">State</label>
                                            <SelectField
                                                name="state_id"
                                                value={formData.state_id}
                                                options={ddFilteredStatesList}
                                                optLabelField="name"
                                                optValueField="id"
                                                onChangeFn={value => handleInputChange(value)} />
                                            {formErrors.state_id && <div className="invalid-feedback">{formErrors.state_id}</div>}
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="form-group">
                                            <label htmlFor="country_id">Country</label>
                                            <SelectField
                                                name="country_id"
                                                value={formData.country_id}
                                                options={ddCountriesList}
                                                optLabelField="name"
                                                optValueField="id"
                                                onChangeFn={value => handleInputChange(value)}                                                className={(formErrors.country_id ? 'is-invalid' : '')} />
                                            {formErrors.country_id && <div className="invalid-feedback">{formErrors.country_id}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col d-flex justify-content-end">
                                        <button type="button" className="btn btn-default" onClick={handleNavigateVendorList}>
                                            <i className="fas fa-angles-left"></i>&nbsp;Go Back
                                        </button>
                                    </div>

                                    <div className="col">
                                        <button type="submit" className="btn btn-primary">
                                            {formData.id ? 'Update' : 'Save'}
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddVendor;
