export default function CustomInputField({name, value, fieldType, onChangeFn = (e) => {}, className = '', disabled = false}) {
    const onFieldChanged = (e) => {
        let value = e.target.value;

        if (value) {
            if (['number', 'phone', 'zip'].includes(fieldType)) {
                value = value.replace(/[^0-9]/g, '');
            }

            if (fieldType == 'phone') {
                value = value.substr(0, 10);
            } else if (fieldType == 'zip') {
                value = value.substr(0, 6);
            } else if (fieldType == 'amount') {
                value = value.replace(/[^0-9.]/g, '');
                console.log('value:', value);

                if (value.includes('.')) {
                    value = value.split('.');

                    if (value.length == 1) {
                        value = value[0] + '.';
                    } else if (value.length >= 2) {
                        value = value[0] + '.' + value[1];
                    }
                }
            }
        }

        e.target.value = value;

        onChangeFn(e);
    };

    return (
        <>

            <input
                type="text"
                name={name}
                className={'form-control ' + className}
                value={value}
                onChange={onFieldChanged}
                disabled={disabled}
            />

        </>
    );
}
