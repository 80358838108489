import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";

export default function SelectField({
    name = '',
    value = '',
    options = '',
    optLabelField = 'name',
    optValueField = 'id',
    onChangeFn = (value) => {},
    placeholder = 'Select an option',
    disabled = false,
    className = '',
    isServerSide = false,
    ajaxDataFetchFn = null,
    noDataPlaceholder = null,
    isClearable = true,
    isMulti = false,
    closeMenuOnSelect = true,
}) {
    const [ selectedValue, setSelectedValue ] = useState({});
    const [ arrayOptions, setArrayOptions ] = useState([]);

    const [ searchText, setSearchText ] = useState('');

    useEffect(() => {
        let newArrayOptions = [];

        if (!isServerSide) {
            if (Array.isArray(options)) {
                newArrayOptions = [...options.filter(op => op)];
            } else {
                let optKeys = Object.keys(options);

                for (let i = 0; i < optKeys.length; i++) {
                    let newArrayObj = {};

                    if (!optKeys[i]) {
                        continue;
                    }

                    if (typeof(options[optKeys[i]]) === 'object') {
                        newArrayObj = {
                            [optValueField]: options[optKeys[i]][optValueField],
                            [optLabelField]: options[optKeys[i]][optLabelField],
                        };
                    } else {
                        newArrayObj = {
                            [optValueField]: optKeys[i],
                            [optLabelField]: options[optKeys[i]],
                        };
                    }

                    newArrayOptions.push({ ...newArrayObj });
                }
            }

            let newSelectedValue = isMulti ? [] : {};

            if (value) {
                for (let i = 0; i < newArrayOptions.length; i++) {
                    if (isMulti) {
                        if (Array.isArray(value)) {
                            for (let vI = 0; vI < value.length; vI++) {
                                if (newArrayOptions[i][optValueField] == value[vI]) {
                                    newSelectedValue.push(newArrayOptions[i]);
                                    break;
                                }
                            }
                        } else {
                            break;
                        }
                    } else {
                        if (newArrayOptions[i][optValueField] == value) {
                            newSelectedValue = newArrayOptions[i];
                            break;
                        }
                    }
                }
            }

            // console.log('newSelectedValue:', newSelectedValue);

            setSelectedValue(newSelectedValue);

            setArrayOptions([...newArrayOptions]);
        }
    }, [options, value]);

    const onFieldChanged = (option) => {
        let eventData = {
            'target': {
                'name': name,
                'value': '',
            },
        };

        if (isMulti) {
            if (Array.isArray(option)) {
                eventData.target.value = option.map(op => op[optValueField]);
                // console.log('eventData.target.value:', eventData.target.value);
            }
        } else if (option && option[optValueField]) {
            eventData.target.value = option[optValueField];
        }

        onChangeFn(eventData);
    };

    const getOptionsFromServer = (inputValue, callback) => {
        if (typeof(ajaxDataFetchFn) === 'function') {
            ajaxDataFetchFn(inputValue, callback);
        } else {
            callback([]);
        }
    };

    const checkIfOptionIsSelected = (option, selectValue) => {
        return option[optValueField] == value;
    };

    return (
        <>
            {
                isServerSide ?
                <AsyncSelect
                    placeholder={placeholder}
                    noOptionsMessage={() => {
                        if (typeof(noDataPlaceholder) === 'function' && noDataPlaceholder(searchText)) {
                            return noDataPlaceholder(searchText);
                        }

                        return 'No options';
                    }}
                    name={name}
                    loadOptions={getOptionsFromServer}
                    getOptionLabel={(option) => option[optLabelField]}
                    getOptionValue={(option) => option[optValueField]}
                    isOptionSelected={checkIfOptionIsSelected}
                    onChange={onFieldChanged}
                    className={className}
                    isDisabled={disabled}
                    onInputChange={newValue => {
                        setSearchText(newValue);
                    }}
                    isClearable={isClearable}
                    isMulti={isMulti}
                    closeMenuOnSelect={closeMenuOnSelect} /> :
                <ReactSelect
                    placeholder={placeholder}
                    name={name}
                    options={arrayOptions}
                    getOptionLabel={(option) => option[optLabelField]}
                    getOptionValue={(option) => option[optValueField]}
                    value={selectedValue}
                    onChange={onFieldChanged}
                    isClearable={isClearable}
                    className={className}
                    isDisabled={disabled}
                    isMulti={isMulti}
                    closeMenuOnSelect={closeMenuOnSelect} />
            }
        </>
    );
}
