import React, { useContext, useEffect, useState } from "react";

import { AuthContext, AuthStates } from "../../auth/authContext";
import { LocalDB } from "../../helpers/localDB";
import { confirmDialog } from "../../helpers/viewHelper";

function TopNavBar({pageTitle, extraIcons}) {
    const [ isBtnLogoutVisible, toggleLogoutBtn ] = useState(false);
    const { appStatus, dispatchAppStatus } = useContext(AuthContext);

    useEffect(() => {
        if (appStatus.state == AuthStates.LOGGED_IN) {
            toggleLogoutBtn(true);
        }
    }, [appStatus]);

    const onBtnLogoutClicked = (e) => {
        e.preventDefault();

        confirmDialog({
            message: 'Are you sure you want to Logout?',
            confirmBtn: {
                onClick: () => {
                    LocalDB.delete('user');

                    dispatchAppStatus({ state: AuthStates.INIT });
                },
            },
            cancelBtn: {
                onClick: () => {},
            },
        });
    };

    return (
        <nav className="main-header navbar navbar-expand">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="javascript:void(0);" role="button">
                        <i className="fas fa-bars"></i>
                    </a>
                </li>

                <li className="nav-item">
                    <a className="nav-link text-inherit" href="javascript:void(0);">{pageTitle}</a>
                </li>
            </ul>

            <ul className="navbar-nav ml-auto">
                {
                    extraIcons && extraIcons.length > 0 &&
                    extraIcons.map((icon) => {
                        return (
                            <li className="nav-item">{icon}</li>
                        );
                    })
                }

                {
                    isBtnLogoutVisible &&
                    <li className="nav-item">
                        <a className="nav-link" href="javascript:void(0);" onClick={onBtnLogoutClicked}>
                            <i className="fas fa-sign-out-alt"></i>
                        </a>
                    </li>
                }
            </ul>
        </nav>
    );
}

export default TopNavBar;
