import React, { useEffect, useState, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import { RequestBackendAPI } from "../helpers/http";

function Dashboard() {
    const { setPageTitle } = useOutletContext();
    const [order, setOrder] = useState([]);
    const [locationOrder, setLocationOrder] = useState([]);
    
    const [infoBoxes, setInfoBoxes] = useState({
        soldOrdersAmount: 0,
        purchasedAmount: 0,
        profitAmount: 0,
        totalOutlets: 0,
    });

    const charts = {
        saleOrdersCount: {
            canvas: useRef(null),
            chartInstance: useRef(null),
        },
        saleOrdersValue: {
            canvas: useRef(null),
            chartInstance: useRef(null),
        },
        saleLocationOrder:{
            canvas: useRef(null),
            chartInstance: useRef(null)
        },
    };

    const chartData = () => {
        RequestBackendAPI({
            path: '/admin/dashboard/charts-data',
            method: 'GET',
            callback: (success, response) => {
                if (success && response.success) {
                    const dashboardData = response.data;
                    console.log("ajax", dashboardData.orders);
                    setOrder(dashboardData.orders);
                    setInfoBoxes(dashboardData.summary);
                    setLocationOrder(dashboardData.locationData)
                }
            }
        });
    };

    useEffect(() => {
        setPageTitle('Dashboard');
        chartData();
    }, []);

    function getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    function loadLocationOrders(){
        const loadPieChart = () => {
            if (window.Chart && charts.saleLocationOrder.canvas.current) {
                const elementLocationOrder = charts.saleLocationOrder.canvas.current.getContext('2d');

                if (charts.saleLocationOrder.chartInstance.current) {
                    charts.saleLocationOrder.chartInstance.current.destroy();
                }
                const locationOrderData = []
                const locationColors = []
                locationOrder.forEach((data)=>{
                    locationOrderData[data.locations] = data.total_amount
                    locationColors.push(getRandomColor())
                });

                const labels = Object.keys(locationOrderData);
                const data = Object.values(locationOrderData);

                var donutData        = {
                    labels: labels,
                    datasets: [
                      {
                        data: data,
                        backgroundColor:locationColors
                      }
                    ]
                  }
                  var donutOptions     = {
                    maintainAspectRatio : false,
                    responsive : true,
                  }
                  //Create pie or douhnut chart
                  // You can switch between pie and douhnut using the method below.
                charts.saleLocationOrder.chartInstance.current =new window.Chart(elementLocationOrder, {
                    type: 'doughnut',
                    data: donutData,
                    options: donutOptions
                })
            }
            
        }
        if (order.length > 0) {
            if (document.readyState === 'complete') {
                loadPieChart();
            } else {
                window.addEventListener('load', loadPieChart);
                return () => window.removeEventListener('load', loadPieChart);
            }
        }
    }
    useEffect(() => {
        const loadChart = () => {
            console.log("Set Order 01 ", order);
            if (window.Chart && charts.saleOrdersCount.canvas.current) {
                const elementOrdersCount = charts.saleOrdersCount.canvas.current.getContext('2d');
                const elementOrdersValue = charts.saleOrdersValue.canvas.current.getContext('2d');

                if (charts.saleOrdersCount.chartInstance.current) {
                    charts.saleOrdersCount.chartInstance.current.destroy();
                }
                if (charts.saleOrdersValue.chartInstance.current) {
                    charts.saleOrdersValue.chartInstance.current.destroy();
                }

                const orderData = [];
                const orderSaleData = [];

                for (let hour = 7; hour < 21; hour++) {
                    let orderCount = 0;
                    let orderAmount = 0;

                    order.forEach((data) => {
                        const orderHour = new Date(data.order_date).getHours();
                        if (hour === orderHour) {
                            orderCount++;
                            orderAmount += data.order_amount;
                        }
                    });

                    orderData[hour] = orderCount;
                    orderSaleData[hour] = orderAmount;
                }

                const labels = Object.keys(orderData);
                const data1 = Object.values(orderData);
                const data2 = Object.values(orderSaleData);

                const dataset1 = [
                    {
                        label: "Order Count",
                        data: data1,
                        fill: true,
                        borderColor: getRandomColor(),
                        tension: 0.5,
                    }
                ];
                const dataset2 = [
                    {
                        label: "Total Amount",
                        data: data2,
                        fill: true,
                        borderColor: getRandomColor(),
                        tension: 0.5,
                    }
                ];

                charts.saleOrdersCount.chartInstance.current = new window.Chart(elementOrdersCount, {
                    type: 'line',
                    data: {
                        labels: labels,
                        datasets: dataset1,
                    }
                });
                charts.saleOrdersValue.chartInstance.current = new window.Chart(elementOrdersValue, {
                    type: 'line',
                    data: {
                        labels: labels,
                        datasets: dataset2,
                    }
                });
            } else {
                console.error('Chart.js not loaded');
            }
        };

        if (order.length > 0) {
            if (document.readyState === 'complete') {
                loadChart();
            } else {
                window.addEventListener('load', loadChart);
                return () => window.removeEventListener('load', loadChart);
            }
        }
        loadLocationOrders();
  
        return () => {
            if (charts.saleOrdersCount.chartInstance.current) {
                charts.saleOrdersCount.chartInstance.current.destroy();
            }
            if (charts.saleOrdersValue.chartInstance.current) {
                charts.saleOrdersValue.chartInstance.current.destroy();
            }
        };
    }, [order]);

    return (
        <>
            <div className="row">
                {/* Your existing info boxes */}
                <div className="col-lg-3 col-6">
                    <div className="small-box bg-info">
                        <div className="inner">
                            <h3>&#8377; {infoBoxes.soldOrdersAmount}</h3>
                            <p>Sold Orders</p>
                        </div>
                        <div className="icon">
                            <i className="fas fa-hand-holding-dollar"></i>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-6">
                    <div className="small-box bg-danger">
                        <div className="inner">
                            <h3>&#8377; {infoBoxes.purchasedAmount}</h3>
                            <p>Purchased</p>
                        </div>
                        <div className="icon">
                            <i className="fas fa-receipt"></i>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-6">
                    <div className="small-box bg-success">
                        <div className="inner">
                            <h3>&#8377; {infoBoxes.profitAmount}</h3>
                            <p>Profit</p>
                        </div>
                        <div className="icon">
                            <i className="fas fa-money-bill-trend-up"></i>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-6">
                    <div className="small-box bg-warning">
                        <div className="inner">
                            <h3>{infoBoxes.totalOutlets}</h3>
                            <p>Shops</p>
                        </div>
                        <div className="icon">
                            <i className="fas fa-store"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <section className="col-lg-6">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                <i className="fas fa-chart-pie mr-1"></i>
                                Sales Order
                            </h3>
                        </div>
                        <div className="card-body">
                            <div className="tab-content p-0">
                                <div className="chart tab-pane active" style={{position: "relative", height: "100%"}}>
                                    <canvas ref={charts.saleOrdersCount.canvas}></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="col-lg-6">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                <i className="fas fa-chart-pie mr-1"></i>
                                Sale Amount
                            </h3>
                        </div>
                        <div className="card-body">
                            <div className="tab-content p-0">
                                <div className="chart tab-pane active" style={{position: "relative", height: "100%"}}>
                                    <canvas ref={charts.saleOrdersValue.canvas}></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="col-lg-6">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                <i className="fas fa-chart-pie mr-1"></i>
                                Location Sale
                            </h3>
                        </div>
                        <div className="card-body">
                            <div className="tab-content p-0">
                                <div className="chart tab-pane active" style={{position: "relative", height: "100%"}}>
                                    <canvas ref={charts.saleLocationOrder.canvas}></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Dashboard;
