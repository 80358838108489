import { useEffect, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import Table from "../../../widgets/Table";
import { objectToFormData, RequestBackendAPI } from "../../../helpers/http";

export default function StockHistoryPage() {
    const { setPageTitle } = useOutletContext();

    const getStockupdatesDownload = () =>{
        RequestBackendAPI({
            path: `productMaster.stockExport`,
            method: 'GET',
            callback: (success, response) => {
                const blob = new Blob([response], { type: "text/csv" });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "Stock-Updates.csv"; 
                a.click();
                window.URL.revokeObjectURL(url);
            }
        })
    }

    const renderStockCountColumn = (rowData, column) => {
        return (
            <>
                {
                    column.column == 'stock_before_update' ? (
                        <span className="">{rowData.stock_before_update}</span>
                    ) : (
                        rowData.stock_after_update > rowData.stock_before_update ?
                        <span className=""><i className="fas fa-arrow-up-long text-success"></i>&nbsp;&nbsp;{rowData.stock_after_update}</span> :

                        rowData.stock_after_update < rowData.stock_before_update ?
                        <span className=""><i className="fas fa-arrow-down-long text-danger"></i>&nbsp;&nbsp;{rowData.stock_after_update}</span> :

                        <span className="">{rowData.stock_after_update}</span>
                    )
                }
            </>
        );
    };

    const columns = [
        {
            'column': 'location',
            'label': 'Location',
            'width': '15%',
        },
        {
            'column': 'product',
            'label': 'Product',
            'width': '15%',
        },
        {
            'column': 'stock_before_update',
            'label': 'Stock count<br>(Before update)',
            'align': 'right',
            'width': '15%',
            'renderFn': renderStockCountColumn,
        },
        {
            'column': 'stock_after_update',
            'label': 'Stock count<br>(After update)',
            'align': 'right',
            'width': '15%',
            'renderFn': renderStockCountColumn,
        },
        {
            'column': 'created_at',
            'label': 'Transferred at',
            'width': '15%',
        },
        {
            'column': 'note',
            'label': 'Note',
            'width': '25%',
        },
    ];

    const tableRef = useRef(null);

    useEffect(() => {
        setPageTitle('Stock History');
    }, []);

    return (
        <>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <div className="row">
                                <div className="col-lg-12 mb-3 d-flex justify-content-end">
                                    <button className="btn btn-success" onClick={getStockupdatesDownload}>
                                        <i className="fas fa-download"></i> Export
                                    </button>
                                </div>
                            </div>

                            <Table
                                apiPath='productMaster.history'
                                reqMethod='POST'
                                ref={tableRef}
                                dataKeyFromResponse='stockUpdates'
                                columns={columns}
                                actionColumnFn={false} />

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
