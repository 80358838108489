const APP_NAME = 'Suluva POS';

const REQ_BACK_END_WITH_JSON_RPC = true;

const API_AUTH_TYPE = 'cookie';

const BACK_END_API_ENDPOINTS = {
    'auth.login': '/web/session/authenticate',
    'auth.check': '/web/session/get_session_info',

    'usersMaster': {
        'list': 'suluva-pos/masters/users/list',
        'dropDowns': 'suluva-pos/masters/users/drop-downs',
        'save': 'suluva-pos/masters/users/save',
        'edit': 'suluva-pos/masters/users/:id/edit',
        'delete': 'suluva-pos/masters/users/:id/delete',
        'changePassword': 'suluva-pos/masters/users/:id/change-password',
    },

    'productMaster': {
        'list': 'suluva-pos/masters/product/list',
        'dropDowns': 'suluva-pos/masters/product/drop-downs',
        'save': 'suluva-pos/masters/product/save',
        'edit': 'suluva-pos/masters/product/:id/edit',
        'delete': 'suluva-pos/masters/product/:id/delete',
        'stockUpdates': 'suluva-pos/masters/product/:id/stock-updates',
        'stockTransfer': {
            'dropDowns': 'suluva-pos/masters/product/:id/stock-transfer/dropDowns',
            'transfer': 'suluva-pos/masters/product/:id/stock-transfer/transfer',
        },
        'history': 'suluva-pos/history/products/stock',
        "stockExport":'suluva-pos/history/products/stock/export',
        'stock': {
            'list': 'suluva-pos/masters/products/stock/list',
        },
    },

    'customerMaster': {
        'list': 'suluva-pos/masters/customer/list',
        'dropDowns': 'suluva-pos/masters/customer/drop-downs',
        'save': 'suluva-pos/masters/customer/save',
        'edit': 'suluva-pos/masters/customer/:id/edit',
        'delete': 'suluva-pos/masters/customer/:id/delete',
    },

    'vendorMaster': {
        'list': 'suluva-pos/masters/vendors/list',
        'dropDowns': 'suluva-pos/masters/vendors/drop-downs',
        'save': 'suluva-pos/masters/vendors/save',
        'edit': 'suluva-pos/masters/vendors/:id/edit',
        'delete': 'suluva-pos/masters/vendors/:id/delete',
    },

    'location.list': '/suluva-pos/masters/warehouse/list',
    'location.save': '/suluva-pos/masters/warehouse/save',
    'location.edit': '/suluva-pos/masters/warehouse/:id/edit',
    'location.delete': '/suluva-pos/masters/warehouse/:id/delete',
    'location.dropDown': '/suluva-pos/masters/warehouse/drop-downs',

    'billing.dropDowns': '/suluva-pos/sale-order/new/drop-downs',
    'billing.calcPrice.by-ProductCode': '/suluva-pos/sale-order/new/calc-product/by-productCode',
    'billing.calcPrice.by-productId': '/suluva-pos/sale-order/new/calc-product/by-product_id',
    'billing.createOrder': '/suluva-pos/sale-order/create',
    'billing.editOrder': '/suluva-pos/sale-order/:id/update',
    'billing.productSearch': '/suluva-pos/sale-order/new/product-search',

    'saleOrder.list': '/suluva-pos/orders/sale-order/list',
    'saleOrder.save': '/suluva-pos/orders/sale-order/save',
    'saleOrder.edit': '/suluva-pos/orders/sale-order/:id/edit', 
    'saleOrder.delete': '/suluva-pos/orders/sale-order/:id/delete',
    'saleOrder.dropDowns': '/suluva-pos/orders/sale-order/drop-downs',
    'saleOrder.status.complete': '/suluva-pos/orders/sale-order/:id/status/complete',
    'saleOrder.status.cancel': '/suluva-pos/orders/sale-order/:id/status/cancel',
    'saleOrder.history.info': '/suluva-pos/history/sale-orders/:id/info',

    'purchaseOrder.list': '/suluva-pos/orders/purchase-order/list',
    'purchaseOrder.save': '/suluva-pos/orders/purchase-order/save',
    'purchaseOrder.edit': '/suluva-pos/orders/purchase-order/:id/edit', 
    'purchaseOrder.delete': '/suluva-pos/orders/purchase-order/:id/delete',
    'purchaseOrder.dropDowns': '/suluva-pos/orders/purchase-order/drop-downs',
    'purchaseOrder.status.complete': '/suluva-pos/orders/purchase-order/:id/status/complete',
    'purchaseOrder.status.cancel': '/suluva-pos/orders/purchase-order/:id/status/cancel',
    'purchaseOrder.history.info': '/suluva-pos/history/purchase-orders/:id/info',

    'order.history.list': '/suluva-pos/history/orders/all',

    'order.history.all.download': '/suluva-pos/history/orders/all/download',

    'manufactureOrder': {
        'list': '/suluva-pos/orders/mrp-production/list',
        'save': '/suluva-pos/orders/mrp-production/save',
        'edit': '/suluva-pos/orders/mrp-production/:id/edit',
        'delete': '/suluva-pos/orders/mrp-production/:id/delete',
        'dropDowns': '/suluva-pos/orders/mrp-production/drop-downs',
        'status': {
            'complete': '/suluva-pos/orders/mrp-production/:id/status/complete',
            'cancel': '/suluva-pos/orders/mrp-production/:id/status/cancel',
        },
        'history': {
            'info': '/suluva-pos/history/manufacture-orders/:id/info',
        },
    },
};

const API_LOGIN_DEFAULT_PARAMS = {
    'db': (process.env.REACT_APP_BACKEND_DB ?? 'suluva_pos'),
};

const API_LOGIN_FIELD_NAMES = {
    'username': 'login',
    'password': 'password',
};

export default {
    APP_NAME,
    REQ_BACK_END_WITH_JSON_RPC,
    API_AUTH_TYPE,
    BACK_END_API_ENDPOINTS,
    API_LOGIN_DEFAULT_PARAMS,
    API_LOGIN_FIELD_NAMES,
};
