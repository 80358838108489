import { useEffect, useRef } from "react";

export default function Modal({title, body, footer, show = false, toggleModalVisibility = () => {}, modalSize = 'large', isScrollable = true}) {
    const modalElementRef = useRef(null);
    const modalClassRef = useRef(null);

    const onCloseModalClicked = (e) => {
        if (typeof(toggleModalVisibility) === 'function') {
            toggleModalVisibility(!show);
        }
    };

    useEffect(() => {
        if (!modalClassRef.current) {
            modalClassRef.current = new window.bootstrap.Modal(modalElementRef.current, {
                'backdrop': 'static',
                'keyboard': false,
            });
        }

        if (show) {
            modalClassRef.current.show();
        } else {
            modalClassRef.current.hide();
        }
    }, [show]);

    return (
        <>
            <div class="modal fade" tabindex="-1" role="dialog" ref={modalElementRef}>
                <div class={
                    "modal-dialog modal-dialog-centered" +
                    (isScrollable ? " modal-dialog-scrollable" : "") + " " +
                    (modalSize == 'small' ? "modal-sm" : (modalSize == "medium" ? "modal-md": "modal-lg"))
                } role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{title}</h5>
                            <button type="button" class="close" onClick={onCloseModalClicked}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            {body}
                        </div>
                        <div class="modal-footer justify-content-center">
                            {footer}
                            <button type="button" class="btn btn-default" onClick={onCloseModalClicked}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
